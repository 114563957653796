<template>
  <div>
    <v-img src="../assets/images/hebergement/hebergement.png" max-height="100vh">
      <div
        style="width: 100vw; display: flex; flex-direction: column; justify-content: center;
    align-items: center; height: 100%;"
      >
    <span style="color: #f49835; font-size: 6vh; background: #5b6d66;
        font-weight: bold; margin-bottom: 5vh"><span style="color: #FFFFFF">DES CHAMBRES
    </span> MODERNES</span>
      </div>
    </v-img>
    <!--------------------------------------------------------------------------------------->
    <v-row justify="center" style="margin-top: 5vh">
      <div style="width: 50vw">
        <span style="font-size: 5vw; font-family: Anton; color: #002034">
          Le cadre <span style="color: #f49835">idéal</span>
          , <span style="color: #f49835">calme</span> et très
          <span style="color: #f49835">confortable</span></span>
      </div>
    </v-row>
    <!--------------------------------------------------------------------------------------->
    <v-row style="margin-top: 10vh; margin-bottom: 2vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="100vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/hebergement/hb.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>

    <!--------------------------------------------------------------------------------------->
    <v-row justify="center" style="margin-top: 15vh; margin-bottom: 15vh; color: #002034">
      <div>
        <p style="font-family: Anton; font-size: 4vw">Au total
          <span style="color:#f49835;">200 chambres </span>de disponibles<br>
          <span>sur le site de l'Académie</span></p>
      </div>
    </v-row>
    <!--------------------------------------------------------------------------------------->
    <v-row style="margin-top: 10vh; margin-bottom: 10vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="45vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/hebergement/hb2.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="45vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/hebergement/hb3.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>
    <!------------------------------------------------------------------------------>
    <v-row style="margin-top: 10vh; margin-bottom: 10vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="30vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/hebergement/hb4.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="30vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/hebergement/hb5.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="30vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/hebergement/hb6.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Hebergement',
};
</script>

<style scoped>

</style>
