<template>
  <div>
    <v-img src="../assets/images/Apropos/mm2.jpg" max-height="25vh">
          <div style="background: transparent; height: 100%; width: 100%; display: flex;
            flex-wrap: nowrap; flex-direction: row; justify-content: center; align-items: end">
            <span style="color: #f49835; font-size: 7vh;
                font-weight: bold;">MISSION</span>
          </div>
    </v-img>
    <div
        style="width: 100vw; display: flex; flex-direction: column; justify-content: center;
    align-items: center; height: 70vh; background: #EFEFEF"
      >
        <p style="color: black; font-size: medium; width: 70%;
        font-family: Raleway;font-size: x-large;">
          &ensp;&ensp;&ensp;&ensp;&ensp;
          L’&ensp;Académie Internationale Léono est un centre d’excellence orienté
          métiers. Les formations dispensées en son sein, ont pour objectif d’édifier les
          auditeurs. Pour maintenir élevée la qualité de la formation y dispensées et ceci
          de façon permanente, l’AIL dans sa stratégie, s’affilie à des entités ayant reçues
          de hautes distinctions et accréditations dans leur domaines d’expertises.
        </p>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Missions',
};
</script>

<style scoped>

</style>
