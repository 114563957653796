<template>
  <div style="background-color: black; height: 100vh;
      display: flex; flex-flow: row nowrap; justify-content: center; align-items: center">
    <h1 style="color: #EFEFEF">Bientôt le début des inscriptions</h1>
  </div>
</template>

<script>
export default {
  name: 'Contact',
};
</script>

<style scoped>

</style>
