import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Formations from '../views/Formations.vue';
import QuiSommesNous from '../views/QuiSommesNous.vue';
import Footer from '../components/Footer.vue';
import Vision from '../views/Vision.vue';
import Valeurs from '../views/Valeurs.vue';
import Equipe from '../views/Equipe.vue';
import Partenaires from '../views/Partenaires.vue';
import Hebergement from '../views/Hebergement.vue';
import Restauration from '../views/Restauration.vue';
import Deplacement from '../views/Deplacement.vue';
import Loisirs from '../views/Loisirs.vue';
import Contact from '../views/Contact.vue';
import NewHome from '../components/NewNavbar.vue';
import Missions from '../views/Missions.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Missions',
    name: 'Missions',
    component: Missions,
  },
  {
    path: '/NewHome',
    name: 'NewHome',
    component: NewHome,
  },
  {
    path: '/Formations',
    name: 'Formations',
    component: Formations,
  },
  {
    path: '/QuiSommesNous',
    name: 'QuiSommesNous',
    component: QuiSommesNous,
  },
  {
    path: '/Footer',
    name: 'Footer',
    component: Footer,
  },
  {
    path: '/Vision',
    name: 'Vision',
    component: Vision,
  },
  {
    path: '/Valeurs',
    name: 'Valeurs',
    component: Valeurs,
  },
  {
    path: '/Equipe',
    name: 'Equipe',
    component: Equipe,
  },
  {
    path: '/Partenaires',
    name: 'Partenaires',
    component: Partenaires,
  },
  {
    path: '/Hebergement',
    name: 'Hebergement',
    component: Hebergement,
  },
  {
    path: '/Restauration',
    name: 'Restauration',
    component: Restauration,
  },
  {
    path: '/Deplacement',
    name: 'Deplacement',
    component: Deplacement,
  },
  {
    path: '/Loisirs',
    name: 'Loisirs',
    component: Loisirs,
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact,
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
