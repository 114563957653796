<template>
  <div>
    <v-img src="../assets/images/food/food1.png" max-height="100vh">
      <div
        style="width: 100vw; display: flex; flex-direction: column; justify-content: center;
    align-items: center; height: 100%;"
      >
    <span style="color: #f49835; font-size: 6vh; background: #5b6d66;
        font-weight: bold; margin-bottom: 5vh"><span style="color: #FFFFFF">DES REPAS
    </span> SANTÉ ET ORIGINAUX</span>
      </div>
    </v-img>
    <!-------------------------------------------------------------------------------------->
    <v-row justify="center" style="margin-top: 5vh">
      <div style="width: 50vw">
        <span style="font-size: 5vw; font-family: Anton; color: #002034">
          Des restaurants et cuisines <span style="color: #f49835">hors classes et équipées</span>
          </span>
      </div>
    </v-row>
<!--    <div-->
<!--      style="width: 100vw; display: flex; flex-direction: row; justify-content: center;-->
<!--      align-items: center; margin-top: 10vh"-->
<!--    >-->
<!--      <v-card-->
<!--        class="mx-auto"-->
<!--        width="80%"-->
<!--        color="#f49835"-->
<!--        style="justify-content: center; align-items: center;-->
<!--          flex-flow: column nowrap; display: flex;"-->
<!--      >-->
<!--        <div style="width: content-box;-->
<!--            display: flex; flex-flow: row nowrap;-->
<!--            justify-content: center; align-items: center">-->
<!--          <v-card-title-->
<!--            style="font-family: Raleway; font-size: xx-large;-->
<!--             font-weight: bold; color: #FFFFFF"-->
<!--          >RESTAURATION</v-card-title>-->
<!--        </div>-->
<!--        <v-card-text style="color: #000000; font-size: xx-large;-->
<!--         text-align: center; width: 80vw;-->
<!--          line-height: 1.3">-->
<!--          Deux restaurants et deux cuisines hors classes et équipées-->
<!--          avec un staff expérimenté à-->
<!--          votre disposition pour des mets culturels. Passez vos-->
<!--          commandes sur place pour manger-->
<!--          à votre aise-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </div>-->
    <!--------------------------------------------------------------------------------------->
    <v-row style="margin-top: 10vh; margin-bottom: 2vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="100vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/food/img.png"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>

    <!--------------------------------------------------------------------------------------->
    <v-row justify="center" style="margin-top: 5vh">
      <div style="width: 50vw">
        <span style="font-size: 5vw; font-family: Anton; color: #002034; white-space: nowrap">
          Une très grande diversité <span style="color: #f49835">culinaire</span>
          </span>
      </div>
    </v-row>
    <!--------------------------------------------------------------------------------------->
    <v-row style="margin-top: 10vh; margin-bottom: 10vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="45vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/food/food2.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="45vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/food/food3.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>
    <!------------------------------------------------------------------------------>
  </div>
</template>

<script>
export default {
  name: 'Restauration',
};
</script>

<style scoped>

</style>
