<template>
  <div>
    <v-img src="../assets/images/vision.jpg" max-height="25vh">
      <div style="background: transparent; height: 100%; width: 100%; display: flex;
            flex-wrap: nowrap; flex-direction: row; justify-content: center; align-items: end">
            <span style="color: #f49835; font-size: 7vh;
                font-weight: bold;">VALEURS</span>
      </div>
    </v-img>
    <div
      style="width: 100vw; display: flex; flex-direction: column; justify-content: center;
    align-items: center; height: auto; background: #EFEFEF"
    >
      <p style="color: black; font-size: medium; width: 70%; margin-top: 7vh; margin-bottom: 7vh;
        font-family: Raleway;font-size: x-large;">
        &ensp;&ensp;Consciente de la vérité, autrefois énoncée par Rabelais mais toujours
        d’actualité, que « Science sans conscience n’est que ruine de l’âme »,
        L’AIL œuvre à éveiller la conscience en ses bénéficiaires, c’est-à-dire
        à les éveiller à la vertu, tout en leur apportant l’instruction, à travers
        ses différents programmes. L’éthique est donc une valeur importante pour
        notre institution. Dans le même ordre d’idées, en regroupant différentes
        cultures et valeurs en son sein, l’institution est bien au fait de
        la relativité éthique et encourage par la même occasion, l’acceptation
        ainsi que l’adaptation aux valeurs d’harmonies et de diversités.
        <br><br>
        L’Académie Internationale léono s’appuie  sur un socle
        de valeurs qui guident ses actions, ainsi nous prônons:
        <br><br>
        <span style="font-weight: bold">Le Respect :</span>Toujours avoir à
        l’esprit que chacun est lui, et mérite que
        l’on respecte sa dignité, qu’importe son rang social, et quelle que soit
        son appartenance culturelle, religieuse ou sa couleur de peau.
        <br><br>
        <span style="font-weight: bold">L’Abnégation :</span>Le service par amour
        pour son prochain et pour l’intérêt général, et non pour l’intérêt personnel
        et égoïste.
        <br><br>
        <span style="font-weight: bold">La Foi : </span>Se souvenir toujours qu’on a toutes
        les possibilités en soi, de réaliser ses désirs. Car, ce que l’esprit humain
        peut concevoir, l’esprit humain peut le réaliser.
        <br><br>
        <span style="font-weight: bold">L’Intégrité :</span>Être fidèle à soi-même,
        c’est-à-dire à ses valeurs morales, sans jamais se laisser corrompre par des
        contingences extérieures.
        <br><br>
        <span style="font-weight: bold">L’Humilité : </span>L’homme n’est rien sans Dieu
        et sans les autres. Garder cela en tête, et s’en souvenir chaque fois qu’on veut
        poser un acte.
        <br><br>
        <span style="font-weight: bold">L’Exemple :</span>Toute personne intervenant dans l’un
        ou l’autre des secteurs de L’Académie Internationale Léono doit être un exemple
        vivant des valeurs qu’elle prône. L’éducation par l’exemple est notre maître-mot.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Valeurs',
};
</script>

<style scoped>

</style>
