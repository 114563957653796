<template>
  <div style="position: relative">
<!--    <v-carousel hide-delimiters>-->
<!--      <v-carousel-item-->
<!--        v-for="(item,i) in items"-->
<!--        :key="i"-->
<!--        :src="item.src"-->
<!--      ></v-carousel-item>-->
<!--    </v-carousel>-->
    <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet">
    <!------------------------------------------------------------------------------------->
<!--    <div style="background: black; height: 15vh; width: 100vw"></div>-->
    <div style="position: relative; height: content-box; width: content-box;
     display: flex;
     flex-direction: row; justify-content: center; align-items: center">
      <div style=" height: 100vh; width: 100vw; position: absolute; z-index: 10">
        <div style="height: 100vh; width: 100vw; display: flex;
        flex-flow: row nowrap; justify-content: center; align-items: center">
          <div class="container">
            <div class="box">

              <div class="title">
                <span class="block"></span>
                <h1>ACADÉMIE INTERNATIONALE LÉONO<span></span></h1>
              </div>
              <div class="role" v-if="!this.Dimension.isMobile">
                <div class="block"></div>
                <p>Construisons ensemble le monde de demain</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <video style=" width: 100vw; height: 100%;"
             autoplay muted>
        <source src="../assets/video/ail.mp4"
                type="video/mp4">

        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <v-row justify="center" style="margin-top: 5vh; margin-bottom: 7vh; color: #002034;">
      <div>
        <p style="font-family: Anton; font-size: 4vw">Plus de
          <span style="color:#f49835;">175 000 </span>pieds carré d'<strong>espaces
        </strong> <br>
          <span style="color: #f49835">multidisciplinaires</span> pour pratiquer vos activités
          <br>
          favorites sur le site de <span style="color: #f49835">l'Académie</span></p>
      </div>
    </v-row>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;
      justify-content: space-around; gap: 100px 200px;
            align-content: space-between">
      <v-card
        class="mx-auto"
        max-width="345"
      >
        <v-img
          src="https://www.faubourgmontbenilde.com/wp-content/uploads/2016/03/AdobeStock_66155527-600x400.jpeg"
          height="200px"
        ></v-img>

        <v-card-title>
          Basketball
        </v-card-title>

        <v-card-subtitle>
          Vous avez l'équipe? Nous avons le local et l'équipement! Notre gymnase est ouvert
          aux groupes sportifs qui souhaitent pratiquer leur sport favori.
        </v-card-subtitle>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="345"
      >
        <v-img
          src="https://www.faubourgmontbenilde.com/wp-content/uploads/2016/03/Piscine_02-600x400.jpg"
          height="200px"
        ></v-img>

        <v-card-title>
          Piscine
        </v-card-title>
        <v-card-subtitle>
          Une piscine en location disponible pour vous.
        </v-card-subtitle>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="344"
      >
        <v-img
          src="https://www.faubourgmontbenilde.com/wp-content/uploads/2016/03/Chapelle-salle-de-reunion_02-600x400.jpg"
          height="200px"
        ></v-img>

        <v-card-title>
          Colloques
        </v-card-title>

        <v-card-subtitle>
          Le Faubourg Mont-Bénilde, avec son hébergement de style dortoir et ses nombreuses
          salles et commodités, accueille avec plaisir les groupes à la recherche d'un lieu
          pour tenir un événement sûr.
        </v-card-subtitle>
      </v-card>

      <v-card
        class="mx-auto"
        max-width="344"
      >
        <v-img
          src="https://www.faubourgmontbenilde.com/wp-content/uploads/2016/03/AdobeStock_53805687-600x400.jpeg"
          height="200px"
        ></v-img>

        <v-card-title>
          Danse
        </v-card-title>

        <v-card-subtitle>
          La salle de danse est un lieu qui s'accommode parfaitement aux événements plus
          intimes ainsi qu'aux cours de danse, de yoga ou autres.
        </v-card-subtitle>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="344"
      >
        <v-img
          src="https://www.faubourgmontbenilde.com/wp-content/uploads/2016/03/AdobeStock_80529169-600x400.jpeg"
          height="200px"
        ></v-img>

        <v-card-title>
          Fête
        </v-card-title>

        <v-card-subtitle>
          Une fête d'enfants avec piscine réservée, accès au gymnase et projection cinéma privée,
          pourquoi pas? Le Faubourg Mont-Bénilde ouvre grandes ses portes aux groupes d'enfants
          pour qu'ils passent une journée
        </v-card-subtitle>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="344"
      >
        <v-img
          src="https://www.faubourgmontbenilde.com/wp-content/uploads/2016/03/AdobeStock_66165223-600x400.jpeg"
          height="200px"
        ></v-img>

        <v-card-title>
          Volleyball
        </v-card-title>

        <v-card-subtitle>
          Pour pratiquer votre « touche » ou votre « smash », le Faubourg Mont-Bénilde est
          un endroit de choix. Votre équipe est la bienvenue.
        </v-card-subtitle>
      </v-card>
    </div>
    <!--------------------------------------------------------------------------------------->
    <!--------------------------------------------------------------------------------------->
    <v-row style="margin-top: 15vh">
    </v-row>
    <!--------------------------------------------------------------------------------------->
    <!--------------------------------------------------------------------------------------->
    <div style="background-color: transparent; display: flex; height: content-box; width: 100vw;
    margin-top: 5vh; margin-bottom: 5vh;
     flex-direction: row; justify-content: center; align-items: center;"
    >
      <video class="videoParfait" style="z-index: 50"
             autoplay   controls muted>
        <source src="../assets/video/parfait.mp4"
                type="video/mp4">

        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
    <v-row justify="center" style="margin-top: 0vh; margin-bottom: 5vh; color: #002034;">
      <div style="">
        <p style="font-family: Anton; font-size: 2.5vw">
          CEO Sègbédji Parfait Aïhounhin
          <span style="color:#f49835;">entretien avec ODD TV</span>
          </p>
      </div>
    </v-row>
    <!--------------------------------------------------------------------------------------->
    <v-row style="margin-top: 15vh; margin-bottom: 2vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="100vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/home/view/home1.png"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>
    <v-row justify="center" style="margin-top: 5vh">
      <div style="width: 50vw">
        <span style="font-size: 5vw; font-family: Anton; color: #002034">
          Un cadre <span style="color: #f49835">boisé</span>
          , <span style="color: #f49835">calme</span> et très
          <span style="color: #f49835">confortable</span></span>
      </div>
    </v-row>
    <!------------------------------------------------------------------------------>
    <v-row style="margin-top: 15vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="40vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/homePresentation/resto.png"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <div style="width: 30vw; margin-right: 4vw">
        <span style="font-size: 5vw; font-family: Anton; color: #002034">
           Restauration sur <span style="color: #f49835">mesure</span></span>
      </div>
      <!------------------------------------------------------------------------------------>
    </v-row>
    <!------------------------------------------------------------------------------>
    <!------------------------------------------------------------------------------>
    <v-row style="margin-top: 15vh">
      <!------------------------------------------------------------------------------------>
      <div style="width: 30vw; margin-left: 10vw">
        <span style="font-size: 5vw; font-family: Anton; color: #002034">
           Une salle de conférence <span style="color: #f49835">moderne</span></span>
      </div>
      <v-card
        class="mx-auto"
        max-width="40vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/homePresentation/spectacle.png"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>
    <!------------------------------------------------------------------------------>
<!--    <div style="height: 100vh; width: 100vw; background: #f49835; margin-top: 5vh">-->
<!--    </div>-->
    <!------------------------------------------------------------------------------>
    <v-row style="margin-top: 15vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="30vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/quebec/q1.png"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="30vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/quebec/q2.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="30vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/quebec/q3.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>
    <!------------------------------------------------------------------------------>
    <!------------------------------------------------------------------------------>
    <v-row style="margin-top: 10vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="30vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/quebec/q4.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="30vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/quebec/q5.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="30vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/quebec/q6.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!----------------------------------------------------------------------------------->
    </v-row>
    <v-row justify="center" style="margin-top: 10vh; margin-bottom: 10vh">
      <div style="width: 90vw">
        <span style="font-size: 5vw; font-family: Impact; color: #002034">
           Des <span style="color: #f49835">beaux</span>
           circuits touristiques à découvrir</span>
      </div>
    </v-row>
  </div>
</template>
<script>

export default {
  name: 'Home',
  data() {
    return {
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
      components: {
      },
    };
  },
  computed: {
    Dimension() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return {
            isMobile: true,
          };
        case 'sm':
          return {
            isMobile: true,
          };
        case 'md':
          return {
            isMobile: false,
          };
        case 'lg':
          return {
            isMobile: false,
          };
        case 'xl':
          return {
            isMobile: false,
          };
        default:
          return {
            isMobile: false,
          };
      }
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.titre{
  color: floralwhite !important;
  font-size: 80px !important;
  font-family: "Gill Sans" !important;
  font-weight: bold !important;
  filter: brightness(1) !important;
  margin-bottom: 20vh;
}
.btnSelection:hover{
  background: #5b6d66 !important;
  color: #FFFFFF !important;
}
.btnSelection{
  margin-top: 4vh;
  background: #f49835 !important;
  color: #FFFFFF !important;
  margin-bottom: 20vh;
}
.titreAutre{
  color: floralwhite !important;
  font-size: 80px !important;
  font-family: "Gill Sans" !important;
  font-weight: bold !important;
  filter: brightness(1) !important;
}
.titreAutrePlus{
  color: #f49835!important;
  font-size: 80px !important;
  font-family: "Gill Sans" !important;
  font-weight: bold !important;
  filter: brightness(1) !important;
}

/*--------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap");

.container {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .box {
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.container .box .title {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
}
.container .box .title .block {
  width: 0%;
  height: inherit;
  background: #f49835;
  position: absolute;
  animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  display: flex;
}
.container .box .title h1 {
  font-family: "Poppins";
  color: #fff;
  font-size: 3vw;
  -webkit-animation: mainFadeIn 2s forwards;
  -o-animation: mainFadeIn 2s forwards;
  animation: mainFadeIn 2s forwards;
  animation-delay: 1.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
  white-space: nowrap;
}
.container .box .title h1 span {
  width: 0px;
  height: 0px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #f49835;
  -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  bottom: 13px;
  right: -12px;
}
.container .box .role {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 15px;
}
.container .box .role .block {
  width: 0%;
  height: inherit;
  background: #5b6d66;
  position: absolute;
  animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  display: flex;
}
.container .box .role p {
  animation: secFadeIn 2s forwards;
  animation-delay: 3.2s;
  opacity: 0;
  font-weight: 400;
  font-family: "Lato";
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #e9d856;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #e9d856;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 13px;
  }
}
@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
footer {
  width: 350px;
  height: 80px;
  background: #ffb510;
  position: absolute;
  right: 0;
  bottom: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: top 0.8s forwards;
  animation-delay: 4s;
}
footer span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #232323;
  font-family: "Poppins";
}
footer span i {
  margin-right: 25px;
  font-size: 22px;
  color: #232323;
  animation: icon 2s forwards;
  animation-delay: 4s;
  opacity: 0;
}

@keyframes top {
  0% {
    opacity: 0;
    bottom: -80px;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}
@keyframes icon {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.3) rotate(-2deg);
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}
.logo{
  opacity: 0.8;
  transition-property: opacity;
  transition-delay: 2s;
}
.videoParfait{
  width: 80%;
  border-width:2px;
  border-style:solid;
  border-color:transparent;
  border-radius: 20px;
}
</style>
