<template>
  <v-footer
    padless
    color="red"
  >
    <v-card
      height="100%"
      width="100%"
      flat
      tile
      color="#5b6d66"
      class="lighten-1 white--text text-center"
    >
      <v-card-text>
<!--        <v-btn-->
<!--          v-for="icon in this.icons"-->
<!--          :key="icon"-->
<!--          class="mx-4 white&#45;&#45;text"-->
<!--          icon-->
<!--        >-->
<!--          <v-icon size="24px">-->
<!--            {{ icon }}-->
<!--          </v-icon>-->
<!--        </v-btn>-->
      </v-card-text>
      <v-row align="center" justify="center">
        <v-col align="center" style="height: 30vh">
          <div style="height: 100%; width: 100%;
            display: flex; justify-content: center; flex-flow: row nowrap; align-items: center">
            <div style="width: fit-content; height: content-box;
              display: flex; flex-flow: column nowrap; justify-content: space-around;
              align-items: start">
              <div>
                <v-icon style="color: #f49835; margin-right: 0.5vw">mdi-map-marker</v-icon>
                <span style="font-weight: bold">1325 Avenue des Pensées,</span>
                Bécancour, Québec, <span style="">G9H 2T1</span>
              </div>
              <div style="margin-top: 1vh">
                <v-icon style="color: #f49835; margin-right: 0.5vw">mdi-phone</v-icon>
                <span style="font-weight: bold">Téléphone :</span> 819-602-8956 poste 221
              </div>
              <div style="margin-top: 1vh">
                <v-icon style="color: #f49835; margin-right: 0.5vw">mdi-phone</v-icon>
                <span style="font-weight: bold">Télécopie :</span> 819-602-8957
              </div>
              <div style="margin-top: 1vh">
                <v-icon style="color: #f49835; margin-right: 0.5vw">mdi-email</v-icon>
                <span style="">secreteriat@ail-canada.org</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col>
          <div style="justify-content: flex-end; margin-right: 1vw;
            display: flex">
            <v-icon class="icon" color="white"
                     style="margin-left: 1vw">mdi-linkedin</v-icon>
            <v-icon class="icon" color="white"
                    style="margin-left: 0.5vw"
                  >mdi-twitter</v-icon>
            <v-icon class="icon" color="white"
                    style="margin-left: 0.5vw"
                   >mdi-instagram</v-icon>
            <v-icon class="icon" color="white"
                    style="margin-left: 0.5vw"
                   >mdi-facebook</v-icon>
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-text class="white--text" align="end">
        {{ new Date().getFullYear() }} - <strong>
        <span style="color: #f49835">AIL</span></strong>
        <span> : Tous droits réservés.</span>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  icons: [
    'mdi-facebook',
    'mdi-twitter',
    'mdi-linkedin',
    'mdi-instagram',
  ],
};
</script>

<style scoped>
.icon:hover{
  cursor: pointer;
  color: #f49835 !important;
}
</style>
