<template>
  <v-app>
    <div class="text-center">
      <v-dialog
        overlay-opacity="0.9"
        v-model="dialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Début des inscriptions  &nbsp;: &nbsp;
            <span style="color: green; font-weight: bold;
            text-align: center">Lundi 10 Octobre 2022</span>
          </v-card-title>

          <v-card-text style="display: flex; flex-direction: row; justify-content: center;
            align-items: center; color: black; font-size: medium; margin-top: 3vh">
            Les inscriptions débutons le lundi 10 Octobre 2022.
            Revennez donc à cette date pour vous inscrire. Merci
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              text
              @click="dialog = false"
              style="font-size: larger"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <NewNavbar v-if="Dimension.showNavigationBarOnDesktop"></NewNavbar>
    <v-main>
      <router-view/>
    </v-main>
    <Footer></Footer>
    <div style=" height: 100vh; width: 100vw; position: fixed; z-index: 0">
      <img src="../src/assets/images/Logo/logo.png"
           style="width: auto; margin-left: 3vw; margin-top: 0vh"
           v-bind:style="{ height: this.Dimension.logoHeight+'vh'}"
           alt="">
    </div>
  </v-app>
<!--  <div style="height: 100vh; width: 100vw; background: #EFEFEF; background: #C4C4C4;-->
<!--    display: flex; flex-flow: column nowrap; justify-content: center;-->
<!--     align-items: center;" v-else>-->
<!--    <span style="font-family: Avenir; font-weight: bold">-->
<!--      Bientôt disponible sur mobile</span><br>-->
<!--    <span>Utiliser un Ordinateur pour visiter le site de L'AIL</span>-->
<!--  </div>-->
</template>

<script>
import NewNavbar from './components/NewNavbar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    NewNavbar,
    Footer,
  },
  computed: {
    Dimension() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return {
            showNavigationBarOnDesktop: false,
            showApplication: false,
            logoHeight: 6,
          };
        case 'sm':
          return {
            showNavigationBarOnDesktop: false,
            showApplication: false,
            logoHeight: 8,
          };
        case 'md':
          return {
            showNavigationBarOnDesktop: false,
            showApplication: false,
            logoHeight: 11,
          };
        case 'lg':
          return {
            showNavigationBarOnDesktop: true,
            showApplication: true,
            logoHeight: 13,
          };
        case 'xl':
          return {
            showNavigationBarOnDesktop: true,
            showApplication: true,
            logoHeight: 14,
          };
        default:
          return {
            showNavigationBarOnDesktop: true,
            showApplication: true,
            logoHeight: 15,
          };
      }
    },
  },
  data: () => ({
    showNavigationBarOnDesktop: true,
    dialog: true,
  }),
};
</script>
