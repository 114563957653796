<template>
  <div>
    <v-img src="../assets/images/loisir/loisir4.jpg" max-height="100vh">
      <div
        style="width: 100vw; display: flex; flex-direction: column; justify-content: center;
    align-items: center; height: 100%;"
      >
    <span style="color: #f49835; font-size: 6vh; background: #5b6d66;
        font-weight: bold; margin-bottom: 5vh"><span style="color: #FFFFFF">
      DES INSTALLATIONS SPORTIVES
    </span> MODERNES</span>
      </div>
    </v-img>
    <!--------------------------------------------------------------------------------------->
    <v-row justify="center" style="margin-top: 5vh">
      <div style="width: 50vw">
        <span style="font-size: 5vw; font-family: Anton; color: #002034;">
          Divers locaux pour combler votre envie de <span style="color: #f49835">
          bouger</span>
          </span>
      </div>
    </v-row>
<!--    <div-->
<!--      style="width: 100vw; display: flex; flex-direction: row; justify-content: center;-->
<!--      align-items: center; margin-top: 10vh"-->
<!--    >-->
<!--      <v-card-->
<!--        class="mx-auto"-->
<!--        width="80%"-->
<!--        color="#f49835"-->
<!--        style="justify-content: center; align-items: center;-->
<!--          flex-flow: column nowrap; display: flex;"-->
<!--      >-->
<!--        <div style="width: content-box;-->
<!--            display: flex; flex-flow: row nowrap;-->
<!--            justify-content: center; align-items: center">-->
<!--          <v-card-title-->
<!--            style="font-family: Raleway; font-size: xx-large; font-weight: bold;-->
<!--             color: #FFFFFF"-->
<!--          >LE SECTEUR SPORTIF</v-card-title>-->
<!--        </div>-->
<!--        <v-card-text style="color: #000000; font-size: xx-large; text-align: center;-->
<!--         width: 80vw;-->
<!--          line-height: 1.3">-->
<!--          L’AIL vous offre divers locaux pour combler votre envie de bouger.-->
<!--          Deux gymnases,-->
<!--          une salle d’aérobie et une salle de jeux sont à votre disposition.-->
<!--          Les deux gymnases-->
<!--          s’adaptent selon l’activité sportive que vous désirez. Ces gymnases-->
<!--          peuvent également-->
<!--          se transformer en salle de spectacle, en salle de réception,-->
<!--          en banquet ou autres.-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </div>-->
    <!--------------------------------------------------------------------------------------->
    <v-row style="margin-top: 10vh; margin-bottom: 2vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="100vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/loisir/loisir6.png"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>

    <!--------------------------------------------------------------------------------------->
<!--    <div-->
<!--      style="width: 100vw; display: flex; flex-direction: row; justify-content: center;-->
<!--      align-items: center; margin-top: 10vh"-->
<!--    >-->
<!--      <v-card-->
<!--        class="mx-auto"-->
<!--        width="80%"-->
<!--        color="#f49835"-->
<!--        style="justify-content: center; align-items: center;-->
<!--          flex-flow: column nowrap; display: flex;"-->
<!--      >-->
<!--        <v-card-text style="color: #000000; font-size: xx-large;-->
<!--         text-align: center; width: 80vw;-->
<!--          line-height: 1.3">-->
<!--          <span style="color: #FFFFFF">Dans la salle de jeux,-->
<!--            des tables de billard, de ping-pong,-->
<!--            de hockey sur coussin d’air et de babyfoot vous attendent.</span>-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </div>-->
    <v-row justify="center" style="margin-top: 5vh">
      <div style="width: 50vw">
        <span style="font-size: 5vw; font-family: Anton; color: #002034;">
          Salle de jeux, Tables de billard,  de ping-pong<span style="color: #f49835">
          hockey sur coussin d’air</span>
          </span>
      </div>
    </v-row>
    <!--------------------------------------------------------------------------------------->
    <v-row style="margin-top: 10vh; margin-bottom: 10vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="45vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/loisir/loisir2.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="45vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/loisir/loisir3.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>
    <!------------------------------------------------------------------------------>
    <!--------------------------------------------------------------------------------------->
<!--    <div-->
<!--      style="width: 100vw; display: flex; flex-direction: row; justify-content: center;-->
<!--      align-items: center; margin-top: 10vh"-->
<!--    >-->
<!--      <v-card-->
<!--        class="mx-auto"-->
<!--        width="80%"-->
<!--        color="#f49835"-->
<!--        style="justify-content: center; align-items: center;-->
<!--          flex-flow: column nowrap; display: flex;"-->
<!--      >-->
<!--        <v-card-text style="color: #000000; font-size: xx-large;-->
<!--         text-align: center; width: 80vw;-->
<!--          line-height: 1.3">-->
<!--          <span style="color: #FFFFFF">-->
<!--            Dans l’Académie, vous aurez accès à un Café/Lounge,-->
<!--            un complexe multi-service,-->
<!--            une salle de conditionnement physique, une salle de-->
<!--            spectacle et une piscine.-->
<!--          </span>-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </div>-->
    <v-row justify="center" style="margin-top: 5vh">
      <div style="width: 50vw">
        <span style="font-size: 5vw; font-family: Anton; color: #002034; white-space: nowrap">
          salle de conditionnement <br> physique,
          <span style="color: #f49835">
          hockey sur coussin <br> d’air</span>, une salle de spectacle
          </span>
      </div>
    </v-row>
    <!--------------------------------------------------------------------------------------->
    <!--------------------------------------------------------------------------------------->
    <v-row style="margin-top: 10vh; margin-bottom: 10vh">
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="45vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/loisir/loisir7.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
      <v-card
        class="mx-auto"
        max-width="45vw"
        elevation="7"
      >
        <v-img
          src="../assets/images/loisir/loisir8.jpg"
          max-height="90vh"
          width="80vw"
          position="relative"
        >
        </v-img>
      </v-card>
      <!------------------------------------------------------------------------------------>
    </v-row>
    <!------------------------------------------------------------------------------>
  </div>
</template>
<script>
export default {
  name: 'Loisirs',
};
</script>

<style scoped>

</style>
