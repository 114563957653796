import Vue from 'vue';
// import LazyTube from 'vue-lazytube';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
// Vue.use(LazyTube);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
