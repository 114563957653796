<template>
  <div>
    <v-img src="../assets/images/mission.jpg" max-height="25vh">
      <div style="background: transparent; height: 100%; width: 100%; display: flex;
            flex-wrap: nowrap; flex-direction: row; justify-content: center; align-items: end">
            <span style="color: #f49835; font-size: 7vh;
                font-weight: bold;">VISION</span>
      </div>
    </v-img>
    <div
      style="width: 100vw; display: flex; flex-direction: column; justify-content: center;
    align-items: center; height: 70vh; background: #EFEFEF"
    >
      <p style="color: black; font-size: medium; width: 70%;
        font-family: Raleway;font-size: x-large;">
        &ensp;&ensp;&ensp;&ensp;&ensp;
        L’&ensp;AIL promeut la liberté en développant les connaissances pratiques
        éprouvées scientifiquement chez ses auditeurs. L’ AIL favorise la justice et
        l’équité sociale et l’harmonie entre les diversités culturelles. Nul ne
        pouvant être heureux tout seul, l’Académie Internationale Léono a
        une vision, celle de contribuer à mener les couches défavorisées à un
        mieux-être social et moral à travers toutes ses activités et au sein des
        différents partenaires associés.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Vision',
};
</script>

<style scoped>

</style>
