<template>
<h1>sdfs</h1>
</template>

<script>
export default {
  name: 'Deplacement',
};
</script>

<style scoped>

</style>
