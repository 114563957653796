<template>
  <div style="background: black">
    <div>
      <v-img src="../assets/images/equipe.jpg" max-height="10vh">
      </v-img>
    </div>
    <div
      style="background: transparent; height: 10vh; width: 100vw; display: flex;
            flex-wrap: nowrap; flex-direction: row; justify-content: center; align-items: end"
    >
                   <span style="color: #f49835; font-size: 6vh;
                font-weight: bold;">ÉQUIPE</span>
    </div>
    <div style="height: auto; width: 100vw; display: flex; margin-top: 10vh;
    margin-bottom: 10vh;
      flex-direction: row; justify-content: center; align-items: center">
      <div style="display: flex; flex-direction: row; flex-wrap: nowrap;
          justify-content: center; align-items: center; width: 100vw">
        <div style="height: auto; width: auto">
          <img src="../assets/images/partenaires/black/Richard.jpg" style="height: 50vh;
              width: auto"
               alt="">
        </div>
        <div style="width: 60vw; display: flex; flex-direction: column;
            justify-content: center; align-items: center">
          <h1 style="color: #FFFFFF">RICHARD DESGAGNÉ, ADM</h1>
          <br>
          <p style="color: white; font-size: medium; width: 90%;
        font-family: Raleway;font-size: larger; text-align: justify">Riche d’une expérience
            humaine digne de mention, il a consacré sa carrière
            à l’amélioration des conditions de vie de l’être humain. Il a notamment
            instauré au Canada une norme en matière d’ergonomie automotrice
            permettant ainsi une meilleure santé aux travailleurs industriels.
            <br><br>
            Il est le co
            fondateur de l’usine Canadienne de la société Knoedler
            Manufacturer, un consortium international en ergonomie.
            Doté de valeurs humaines peu communes et d’un grand sens des
            responsabilités, il est un homme engagé et résolu à construire un monde
            meilleur .
            <br><br>
            Doté d’une détermination remarquable
            il a su reconnaître les forces et les
            faiblesses du système de santé et de sa propension à laisser pour compte les
            gens souffrant physiquement, émotionnellement, l’ayant lui même
            expérimenté. Depuis son retour à la santé il consacre son temps à aider les
            gens qui le sou haitent à reconquérir leur plein potentiel contribuant ainsi à
            notre richesse collective.
            <br><br>
            Pour contribuer à cette richesse, il crée un système unique de fidélité pour la
            communauté grandissante du commerce électronique. Détenteur de droit
            d ’auteur sur ce concept unique de partage de la richesse. Soucieux de se
            diversifier et ainsi accroître sa capacité d’aider l’humain à regagner et garder
            sa capacité de création, il fonde en 2015 un consortium de communautés
            sous le nom de Léono dont il par tage la destiné avec son associé, Dr. Sègbédji
            Parfait Aïhounhin
            Leader
            <br><br>
            Leader né, visionnaire et communicateur il a toujours des projets de société
            en cogitation qui ne manqueront pas de nous étonner.
          </p>
        </div>
      </div>
    </div>
    <!----------------------------------------------------------------------------------->
    <div style="height: auto; width: 100vw;">
      <div style="display: flex; flex-direction: row; flex-wrap: nowrap;
          justify-content: center; align-items: center; width: 100vw; margin-bottom: 5vh">
        <div style="width: 60vw; display: flex; flex-direction: column;
            justify-content: center; align-items: center">
          <h1 style="color: #FFFFFF">Dr. Sègbédji Parfait Aïhounhin, ADM</h1>
          <br>
          <p style="color: white; font-size: medium; width: 90%;
        font-family: Raleway;font-size: larger; text-align: justify">
            Entrepreneur engagé et consultant pluridisciplinaire, M. Aïhounhin est titulaire
            du baccalauréat en comptabilité, du diplôme
            universitaire en économique générale, de la licence en gestion, de la maitrise
            en gestion des organisations, d’un master en
            développement, environnement et sociétés, d’un DESS en gestion des projets et
            développement local, d’un doctorat en stratégies et
            gestion internationales d’entreprises et d’une dizaine de certificats en gestion
            financière et comptable. Il est auteur de livres et
            d’articles scientifiques publiés sur la croissance d’entreprises. Il a travaillé
            en Afrique comme spécialiste en évaluation. Il a aussi
            exécuté des contrats de consultance en monitoring pour la Banque mondiale et
            le Millenium Challenge Account (USA). Il est expert en
            Gestion Axée sur les Résultats (GAR), en mise en oeuvre de la stratégie SWAP
            (Sector Wide Approch) et en Budget-Programme. M.
            Aïhounhin a une forte expérience dans les négociations multilatérales et la
            mobilisation des ressources financières, en planification et
            arbitrages budgétaires. Au Bénin, M. Aïhounhin est CEO de Hokmah City Group (HCG)
            et DG de Legendary Manuvo Trading (LMT). Au
            canada, M. Aïhounhin est PCA de la Fondation de l’Alliance, associés au sein de
            l’Académie Internationale Léono, membre actif de
            l’Initiative de l'Internet Physique pour l’Afrique. Au sein du Groupe Léono,
            où il est co-actionnaire, il intervient comme Président de la
            division solaire Léono et a la charge des opérations. À l’institut CEDIMES,
            l’Académie Internationale Léono et à l’université Laval, il
            intervient comme enseignant chercheur.
          </p>
        </div>
        <div style="height: auto; width: auto">
          <img src="../assets/images/partenaires/black/Parfait.jpg" style="height: 50vh;
              width: auto"
               alt="">
        </div>
      </div>
    </div>
    <!----------------------------------------------------------------------------------->
    <div style="height: auto; width: 100vw;">
      <div style="display: flex; flex-direction: row; flex-wrap: nowrap;
          justify-content: center; align-items: center; width: 100vw; margin-bottom: 5vh">
        <div style="height: auto; width: auto">
          <img src="../assets/images/partenaires/black/Steve.jpg" style="height: 50vh;
              width: auto"
               alt="">
        </div>
        <div style="width: 60vw; display: flex; flex-direction: column;
            justify-content: center; align-items: center">
          <h1 style="color: #FFFFFF">STEVE GARCEAU, ADM</h1>
          <br>
          <p style="color: white; font-size: medium; width: 90%;
        font-family: Raleway;font-size: larger; text-align: justify">
            Dès son enfance, Steve a commencé à performer dans les sports et les jeux de logique,
            cherchant toujours des stratégies et des solutions.  Détenteur d’un Bac et
            un certificat en éducation, il a œuvré pendant 17 ans, en tant que professeur
            de mathématiques.
            <br><br>
            Constamment à la rechercher de nouveaux défis et doté d'un côté humain remarquable,
            il a rapidement senti le besoin d'un nouveau défi lui permettant de se surpasser dans
            la vie.   Il a tôt fait de se lancer en affaires et l’immobilier lui apparaissait
            idéal pour réaliser son plein potentiel.
            <br><br>
            Cette nouvelle aventure débute par l’acquisition du Faubourg Mont-Bénilde,
            il dira plus tard que cette acquisition fait sa plus grande fierté. Homme engagé,
            il continue à métamorphoser le domaine immobilier par plusieurs acquisitions
            d’entreprises et d’édifices commerciales.
            <br><br>
            Avec à son actif 15 années d'expérience dans le domaine immobilier, il demeure une
            personne très en vue et respectée dans son milieu.
            Homme de cœur,  généreux et fonceur,  père et mari attentionné, il sait concilier
            sa vie de famille et d'homme d'affaires. Éternel positif, il ne baisse jamais les
            bras pour atteindre ses objectifs.
          </p>
        </div>
      </div>
    </div>
    <!----------------------------------------------------------------------------------->
    <div style="height: auto; width: 100vw;">
      <div style="display: flex; flex-direction: row; flex-wrap: nowrap;
          justify-content: center; align-items: center; width: 100vw">
        <div style="width: 60vw; display: flex; flex-direction: column;
            justify-content: center; align-items: center">
          <h1 style="color: #FFFFFF">Karine Ouellette</h1>
          <br>
          <p style="color: white; font-size: medium; width: 90%;
        font-family: Raleway;font-size: larger; text-align: justify">
            Karine Ouellette, passionnée de la gestion et de l’organisation, est à l’emploi du
            Faubourg Mont-Bénilde depuis septembre 2015, comme gestionnaire. Dévouée et responsable,
            elle se charge des locations ponctuelles, réservations, des baux locatifs, ainsi que de
            la gestion du personnel et des finances. À l’écoute de la clientèle, Karine travaille
            toujours à développer et améliorer les installations. Titulaire d’un baccalauréat en
            récréologie, d’un certificat en administration et d’un certificat en ressources
            humaines, Karine a œuvré comme récréologue et gestionnaire dans une grande variété
            de domaines, tel que des milieux communautaires, des milieux scolaires, en centre
            d’hébergement en soin longue durée et dans des centres sportifs. De plus, elle offre
            son temps bénévolement à plusieurs associations reliées aux loisirs et/ou aux sports.
          </p>
        </div>
        <div style="height: auto; width: auto">
          <img src="../assets/images/partenaires/black/Karine.jpg" style="height: 50vh;
              width: auto"
               alt="">
        </div>
      </div>
    </div>
    <!----------------------------------------------------------------------------------->
  </div>
</template>

<script>
export default {
  name: 'Equipe',
};
</script>

<style scoped>

</style>
