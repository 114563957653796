<template>
  <div style="background: floralwhite">
    <v-img src="../assets/images/vision.jpg" max-height="25vh">
      <div style="background: transparent; height: 100%; width: 100%; display: flex;
       margin-top: 1vh;
            flex-wrap: nowrap; flex-direction: row; justify-content: center; align-items: end">
            <span style="color: #f49835; font-size: 6vh;
                font-weight: bold;">CALENDRIER DE FORMATION</span>
      </div>
    </v-img>
      <div
        style="width: 100vw; display: flex; flex-direction: row; justify-content: center;
      align-items: center; margin-top: 0vh;"
      >
        <v-card
          class="mx-auto"
          width="80%"
          color="#FFFFFF"
          style="justify-content: center; align-items: center; margin-top: 10vh;
          flex-flow: column nowrap; display: flex; opacity: 0.7"
        >
          <div style="width: content-box;
            display: flex; flex-flow: row nowrap;
            justify-content: center; align-items: center">
            <v-card-title
              style="font-family: Raleway; font-size: xx-large"
            >UNE OFFRE THÉMATIQUE GÉOGRAPHIQUEMENT DIVERSIFIÉE!</v-card-title>
          </div>
          <v-card-text style="color: #000000; font-size: large; text-align: center; width: 50vw">
            L’Institut offre des activités de formation pour renforcer les habiletés,
            les capacités et les connaissances des professionnels de la fonction publique,
            dans tous les secteurs liés à la gouvernance, la gestion axée sur les résultats,
            la gestion des programmes et projets publics, la gestion du cycle des marchés
            publics et au suivi-évaluation des programmes publics et systèmes d’information.
          </v-card-text>
        </v-card>
      </div>
      <!-------------------------------------------------------------------------------->
      <!-------------------------------------------------------------------------------->
      <!-------------------------------------------------------------------------------->
      <div
        style="width: 100vw; display: flex; flex-direction: row; justify-content: center;
      align-items: center; margin-top: 30px"
      >
  <!--      <p>-->
  <!--        <span style="font-size: larger; font-weight: bold">-->
  <!--          le test test test test :</span><br>-->
  <!--        <span>safsafsafsdfsafsafasfdasfsadfsafasddfsdfsaf</span>-->
  <!--      </p>-->
      </div>
      <!-------------------------------------------------------------------------------->
      <div
        style="display: flex; flex-flow: column nowrap;
      justify-content: space-around; width: 100vw;
      height: auto; align-items: center; margin-top: 30px;"
      >
        <!-------------------------------------------------------------------------------->
        <!-------------------------------------------------------------------------------->
        <div style="width: 80%">
          <v-expansion-panels focusable>
            <!-------------------------------------------------------------------------------->
            <v-expansion-panel class="expansionPanel">
              <v-expansion-panel-header v-bind:class="{addColor: this.panelHeader1}"
                class="expansionPanelHeader" @mouseover="changeTextColor1"
                                        @mouseleave="putDefaultColor1"
                style="display: flex;
              flex-direction: row; justify-content: center;"
              >
                <span
                  v-bind:class="{makeTextWhite: this.isWhiteHeader1,
                   makeTextOrange: this.isOrangeHeader1}"
                  style="font-size: larger;
                font-weight: bold"
                >FORMATION CERTIFIANTE EN SANTÉ</span>
                <div style="display: flex; flex-direction: row; justify-content: flex-end">
                  <v-chip
                    @click="formation1"
                    color="#5b6d66"
                    text-color="white"
                    style="margin-right: 20px"
                  >
                    Choisir cette formation
                  </v-chip>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="display: flex; flex-flow: row nowrap;
                  justify-content: center; align-items: center; font-family: Raleway">
                  <h2>Programmes courts</h2>
                </div>
                <div style="margin-top: 10px">
                  <v-simple-table style="background: #EEEEEE">
                    <template v-slot:default>
                      <thead>
                      <tr style="background: black;">
                        <th class="text-left" style="color: #FFFFFF">
                          TITRE DE LA FORMATION
                        </th>
                        <th class="text-left" style="color: #FFFFFF">
                          MODALITÉ
                        </th>
                        <th class="text-left" style="color: #FFFFFF">
                          DURÉE
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td style="font-weight: bold">AGENT RÉGIONAL EN SANTÉ
                          ET MESURE D’URGENCE</td>
                        <td style="font-weight: bold">Présentiel</td>
                        <td style="font-weight: bold; white-space: nowrap;">8 semaines</td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <span style="font-weight: bold">Module 1 :</span>
                          Premier répondant et gestion des mesures d’urgence</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <span style="font-weight: bold">Module 2 :</span>
                          Anatomie et physiologie, prévention des infections</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <span style="font-weight: bold">Module 3 :</span>
                          Leadership et communication en santé et mesures d’urgence</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td style="font-weight: bold">PREMIERS SOINS EN SITUATION
                          HOSTILE (PSSH)</td>
                        <td style="font-weight: bold">Présentiel</td>
                        <td style="font-weight: bold">2 jours</td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td class="PutContentWhite">
                          Cette formation conçue pour les forces de l’ordre, militaire ou civile
                          est adaptée pour chaque groupe. Elle regroupe les techniques
                          d’application
                          des premiers soins en situation hostile ou de victime multiples.</td>
                        <td class="PutContentWhite"></td>
                        <td class="PutContentWhite"></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td style="font-weight: bold">PROGRAMME DE PERFECTIONNEMENT EN PSSH</td>
                        <td style="font-weight: bold">Présentiel</td>
                        <td style="font-weight: bold">3 jours</td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td class="PutContentWhite">
                          Les participants ayant préalablement complété le programme PSSH
                          pourront se perfectionner à travers une série de scénario et d’atelier
                          afin d’augmenter leur capacité à gérer efficacement des blessés,
                          en situation hostile. Ils augmenteront leur capacité technique
                          et psychologique.</td>
                        <td class="PutContentWhite"></td>
                        <td class="PutContentWhite"></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td style="font-weight: bold">PROGRAMME DE SECOURISME GÉNÉRAL AVANCÉ</td>
                        <td style="font-weight: bold">Présentiel</td>
                        <td style="font-weight: bold">4 jours</td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td class="PutContentWhite">
                          Ce programme s’adresse à tous. Chaque participant sera entrainé à
                          prendre en charge une situation d’urgence et porter assistance à une
                          victime de malaise ou d’accident. Ce programme se démarque des cours
                          de secourisme standard par l’approche innovante des simulations et de
                          l’apprentissage participatif.</td>
                        <td class="PutContentWhite"></td>
                        <td class="PutContentWhite"></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <!------------------------------------------------------------------------------>
                <div style="display: flex; flex-flow: row nowrap;
                  justify-content: center; align-items: center; font-family: Raleway">
                  <h2>Programme long</h2></div>
                <!------------------------------------------------------------------------------->
                <v-simple-table style="background: #EEEEEE">
                  <template v-slot:default>
                    <thead>
                    <tr style="background: black;">
                      <th class="text-left" style="color: #FFFFFF">
                        TITRE DE LA FORMATION
                      </th>
                      <th class="text-left" style="color: #FFFFFF">
                        MODALITÉ
                      </th>
                      <th class="text-left" style="color: #FFFFFF">
                        DURÉE
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <!--------------------------------------------------------------------->
                    <tr
                    >
                      <td style="font-weight: bold">TECHNICIEN SOINS PRIMAIRES</td>
                      <td style="font-weight: bold">Présentiel</td>
                      <td style="font-weight: bold;white-space: nowrap;">40 semaines</td>
                    </tr>
                    <!--------------------------------------------------------------------->
                    <tr
                    >
                      <td class="PutContentWhite">
                        Ce programme professionnel forme des techniciens qui pourront prendre
                        en charge des personnes victimes de problèmes médicaux ou traumatiques.
                        Ils seront en mesure de gérer une situation d’urgence, donner les soins
                        appropriés aux patients et les transporter vers l’hôpital désigné à bord
                        d’un véhicule d’urgence.</td>
                      <td class="PutContentWhite"></td>
                      <td class="PutContentWhite" style=" white-space: nowrap;">* en résidence</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <br>
                <br>
                <h2 class="putTheGoodStyle" style="font-weight: bold">Note :</h2>
                <ul style="margin-left: 2vw">
                  <li>Les personnes qualifiées en tant qu’agent régional en santé
                    et mesure d’urgence
                    et de technicien en soins primaires sont assujettis aux politiques et règlement
                    de leur direction médicale locale.</li>
                  <li>Les candidats au cours d’agent régional en santé et mesure d’urgence et de
                    technicien en soins primaires devront se procurer l’équipement de sécurité
                    obligatoire (fourni sur place).</li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-------------------------------------------------------------------------------->
            <v-expansion-panel class="expansionPanel">
              <v-expansion-panel-header v-bind:class="{addColor: this.panelHeader2}"
                class="expansionPanelHeader"
                style="display: flex;
              flex-direction: row; justify-content: center;"
              >
                <span style="font-size: larger; font-weight: bold" class="titlePanelHeader">
                  FORMATION CERTIFIANTE EN GOUVERNANCE </span>
                <div style="display: flex; flex-direction: row; justify-content: flex-end">
                  <v-chip
                    color="#5b6d66"
                    text-color="white"
                    style="margin-right: 20px"
                  >
                    Choisir cette formation
                  </v-chip>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="margin-top: 10px">
                  <h2 class="putTheGoodStyle" style="font-weight: bold">THEME DE FORMATION PRATIQUE
                    EN GOUVERNANCE :</h2>
                  <br>
                  <p>
                    L'évolution générale des sociétés démocratiques contemporaines avait déjà
                    conduit depuis une dizaine d'années à des changements significatifs des formes
                    d'expression ouvertes au citoyen par la démocratie représentative et
                    la démocratie participative. La convention d'Aarhus, puis la charte de
                    l'environnement en France, a ainsi érigé en principe de droit la participation
                    du public à l'élaboration des décisions publiques ayant une incidence sur
                    l'environnement. Ces évolutions ont conduit à expérimenter des modes de
                    concertation avec les citoyens qui concourent à leur plus grande implication
                    dans les décisions publiques.
                  </p>
                  <br>
                  <p>
                    C’est donc pour conforter ces expériences que les formations pratiques au
                    profit des acteurs civiles sont initiées.
                  </p>
                  <p>
                    Elles visent à les capaciter afin d’intervenir de façon plus efficiente dans
                    la démarche globale de gouvernance soit en tant qu’acteur libre ou assermenté.
                  </p>
                  <p>
                    Les formations dont les présentes syllabus sont fournis s’inscrivent d’emblée
                    dans la dynamique d’offrir des moyens d’agir concrets aux
                    citoyens de tous bords.
                  </p><br>
                  <div style="width: 100%; display: flex; flex-direction: row;
                   justify-content: center; align-items: center">
                      <h2>1. GOUVERNANCE ET CONTROLE SOCIAL DE L’ACTION PUBLIQUE</h2>
                  </div>
                  <br>
                  <div style="width: 100%; display: flex; flex-direction: row;
                   justify-content: center; align-items: center; margin-bottom: 2vh">
                    <h2 style="text-decoration: underline; color: #f49835">Modules de formation</h2>
                  </div>
                  <!------------------------------------------------------------------------->
                  <v-simple-table style="background: #EEEEEE">
                    <template v-slot:default>
                      <thead>
                      <tr style="background: black;">
                        <th class="text-left" style="color: #FFFFFF">
                          <h2 style="font-weight: bold"> Module 1  &ensp;&ensp;&ensp;&ensp;: </h2>
                        </th>
                        <th class="text-left" style="color: #FFFFFF">
                          <h2>Les mécanismes de gouvernance concertée</h2>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3 style="font-weight: bold">Problématique</h3> </td>
                        <td>
                          <br>
                          <p>
                            La concertation consiste pour différentes
                            catégories
                            d’acteurs d’être ensemble, dans un processus de travail collectif,
                            d’organiser des échanges, même contradictoires, pour se projeter
                            collectivement, et parvenir à des décisions et à une action publique
                            qui conviennent à tous et à chacun.
                            Elle s’attache donc à la contribution de tous les acteurs, tout en
                            poursuivant la construction de consensus autour d’une vision et de
                            valeurs partagées, autour de projets collectifs.
                            Elle répond à des normes qu’il est nécessaire de maitriser au
                            préalable pour ne pas transformer un outil de gouvernance et de
                            consensus en un outil de division.
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Public cible </h3>
                        </td>
                        <td>
                          <ul>
                            <li>Étudiants directs
                            </li>
                            <li>
                              Professionnels des collectivités territoriales.
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Volume horaire</h3>
                          </td>
                        <td> <span style="font-weight: bold">
                          40h dont 15h théorie et 25h de pratique
                        </span></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3>Objectif général </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Permettre aux acteurs civils de s’approprier les logiques et processus
                            d’une bonne concertation et une participation des citoyens et des
                            organisations de la société civile à la gestion des affaires locales
                            grâce à la maitrise des outils juridiques de la gouvernance concertée.
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Objectifs pédagogiques </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Aux termes de la formation, les participants devront
                            être en mesure de :
                          </p>
                          <ul style="margin-left: 2vw">
                            <li>Faire la part des choses entre concertation et les
                              autres concepts voisins.</li>
                            <li>Maitriser le processus de mise en
                              place des cadres de concertation</li>
                            <li>Identifier clairement les parties prenantes au processus
                              de concertation de même que leurs rôles respectifs.</li>
                            <li>Maitriser les enjeux et les fondements de la concertation.</li>
                            <li>Assimiler les outils de la gouvernance concertée</li>
                          </ul>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Contenu</h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Les outils juridiques de la gouvernance concertée
                            <br>
                            Enjeux et fondement de la concertation
                            <br>
                            Les cadres et mécanismes de la concertation
                            <br>
                            Fonctionnement des cadres de concertation.
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!--------------------------------------------------------------------->
                  <!--------------------------Module 2-------------------------->
                  <!--------------------------------------------------------------------->
                  <v-simple-table style="background: #EEEEEE">
                    <template v-slot:default>
                      <thead>
                      <tr style="background: black;">
                        <th class="text-left" style="color: #FFFFFF">
                          <h2 style="font-weight: bold"> Module 2  &ensp;&ensp;&ensp;&ensp;: </h2>
                        </th>
                        <th class="text-left" style="color: #FFFFFF">
                          <h2>Les outils du contrôle citoyen de l'action publique
                          </h2>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td><h3 style="font-weight: bold">Problématique</h3> </td>
                        <td>
                          <br>
                          <p>
                            L’exercice du contrôle citoyen de l’action publique participe d’un
                            processus d’asseoir la bonne gouvernance avec en prime la confiance
                            mutuelle entre gouvernant et gouverné.
                            Le contrôle citoyen de l’action publique s’applique aussi bien à
                            l’Etat central qu’aux collectivités locales. L’acteur possédant
                            les outils adéquats est donc polyvalent et sera la courroie de
                            confiance entre communauté et gouvernants.
                            La confiance seule ne suffit plus aujourd’hui dans le contexte
                            de la gouvernance. Il faut disposer des outils adéquats de suivi
                            et de contrôle.
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Public cible </h3>
                        </td>
                        <td>
                          <ul>
                            <li>Étudiants directs
                            </li>
                            <li>
                              Professionnels des collectivités territoriales.
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Volume horaire</h3>
                        </td>
                        <td> <span style="font-weight: bold">
                          40h dont 10h théorie et 30h de pratique
                        </span></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3>Objectif général </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Permettre aux acteurs civils de s’approprier le processus de
                            participation des citoyens et des organisations de
                            la société civile à la gestion des affaires tant nationales que locales.
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Objectifs pédagogiques </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Aux termes de la formation, les participants
                            devront être en mesure de :
                          </p>
                          <ul style="margin-left: 2vw">
                            <li>Maitriser le concept de « contrôle citoyen de l’action
                              publique » et le distinguer des concepts voisins</li>
                            <li>
                              Maitriser le processus, les conditions et les acteurs
                              en présence dans le cadre du contrôle citoyen de l’action publique
                            </li>
                          </ul>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Contenu</h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Le concept du contrôle citoyen de l’action publique
                            <br>
                            Les outils, mécanismes et limites du contrôle citoyen
                            de l’action publique
                            <br>
                            Les acteurs du contrôle citoyen de l’action publique
                            <br>
                            Portée du contrôle citoyen de l’action publique
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!--------------------------------------------------------------------->
                  <div style="width: 100%; display: flex; flex-direction: row;
                   justify-content: center; align-items: center">
                    <h2>2. GOUVERNANCE PAIX ET SECURITE</h2>
                  </div>
                  <br>
                  <div style="width: 100%; display: flex; flex-direction: row;
                   justify-content: center; align-items: center; margin-bottom: 2vh">
                    <h2 style="text-decoration: underline; color: #f49835">Modules de formation</h2>
                  </div>
                  <!--------------------------------------------------------->
                  <v-simple-table style="background: #EEEEEE">
                    <template v-slot:default>
                      <thead>
                      <tr style="background: black;">
                        <th class="text-left" style="color: #FFFFFF">
                          <h2 style="font-weight: bold"> Module 1  &ensp;&ensp;&ensp;&ensp;: </h2>
                        </th>
                        <th class="text-left" style="color: #FFFFFF">
                          <h2>La gouvernance sécurité en question
                          </h2>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td><h3 style="font-weight: bold">Problématique</h3> </td>
                        <td>
                          <br>
                          <p>
                            La bonne gouvernance rime avec l’absence de situation de conflit
                            et la promotion d’un cadre apaisé pour les communautés. Cette
                            situation qui est un idéal peut être obtenue si tous les acteurs
                            intervenants dans le processus de gouvernance en maitrise les rouages.
                            La sécurité étant transversale à tout le processus de la gouvernance,
                            son appropriation dans les moindres détails constituerait un atout
                            majeur pour les communautés.
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Public cible </h3>
                        </td>
                        <td>
                          <ul>
                            <li>Étudiants directs
                            </li>
                            <li>
                              Professionnels des collectivités territoriales.
                            </li>
                            <li>
                              leaders
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Volume horaire</h3>
                        </td>
                        <td> <span style="font-weight: bold">
                          20h dont 10h théorie et 10h de pratique
                        </span></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3>Objectif général </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Renforcer les connaissances de base des acteurs en gouvernance sécurité.
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Objectifs pédagogiques </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Aux termes de la formation,
                            les participants devront être en mesure de :
                          </p>
                          <ul style="margin-left: 2vw">
                            <li>Assimiler les notions de base de la sécurité dans
                              le contexte de la gouvernance locale et nationale</li>
                            <li>Identifier les acteurs de a chaines de sécurité ainsi
                              que les différents paliers de sécurité</li>
                            <li>Connaitre les différentes structures qui interviennent dans
                              la gouvernance de la sécurité et leurs rôles respectifs</li>
                            <li>Maitriser les dispositions légales qui régissent la gouvernance
                              de la sécurité.</li>
                          </ul>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Contenu</h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Notions de base de la sécurité (locale et nationale)
                            <br>
                            Le cadre de manifestation de la sécurité
                            <br>
                            La chaîne de la gouvernance sécurité
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!--------------------------------------------------------------------->
                  <v-simple-table style="background: #EEEEEE">
                    <template v-slot:default>
                      <thead>
                      <tr style="background: black;">
                        <th class="text-left" style="color: #FFFFFF">
                          <h2 style="font-weight: bold"> Module 2  &ensp;&ensp;&ensp;&ensp;: </h2>
                        </th>
                        <th class="text-left" style="color: #FFFFFF">
                          <h2>Résolution de Conflits, Négociation, Plaidoyer
                          </h2>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td><h3 style="font-weight: bold">Problématique</h3> </td>
                        <td>
                          <br>
                          <p>
                            Rôle de chacun des acteurs (collectivités locales, société civile,
                            acteurs socio-économiques) dans la gouvernance locale. Autrement dit,
                            comment optimiser la mise en œuvre  des outils et instruments de la
                            participation des citoyens dans l’action publique locale ?
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Public cible </h3>
                        </td>
                        <td>
                          <ul>
                            <li>Étudiants directs
                            </li>
                            <li>
                              Professionnels des collectivités territoriales.
                            </li>
                            <li>
                              leaders
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Volume horaire</h3>
                        </td>
                        <td> <span style="font-weight: bold">
                          50h dont 20h théorie et 30h de pratique
                        </span></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3>Objectif général </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Permettre aux acteurs civils de définir le plaidoyer, de montrer son
                            importance et renforcer leurs capacités à faire un plaidoyer. Au terme
                            de la session les participants seront capables de distinguer les
                            différentes formes et sources de conflits, de les prévenir ou de les
                            traiter. Rendre les participants capables de comprendre la négociation
                            et de négocier efficacement. .
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Objectifs pédagogiques </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Aux termes de la formation, les participants
                            devront être en mesure de :
                          </p>
                          <ul style="margin-left: 2vw">
                            <li>définir le plaidoyer,</li>
                            <li>montrer son importance et pouvoir faire un plaidoyer. </li>
                            <li>distinguer les différentes formes et sources de conflits, </li>
                            <li>prévenir ou traiter les conflits. </li>
                            <li>comprendre la négociation et négocier efficacement.</li>
                          </ul>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Contenu</h3>
                        </td>
                        <td>
                          <br>
                          <ul style="margin-left: 2vw">
                            <h4>Résolution de Conflits</h4>
                            <li>Types et sources de conflits</li>
                            <li>Signes d’un conflit potentiel</li>
                            <li>Prévention de conflits</li>
                            <li>Gestion de conflits</li>
                            <li>Médiation entre parties en conflit</li>
                            <li>Gestion des adversités</li>
                            <li>Recherche d’alternatives</li>
                          </ul>
                          <ul style="margin-left: 2vw">
                            <h4>Négociation</h4>
                            <li>Définition et utilité de la négociation</li>
                            <li>Principes de négociation</li>
                            <li>Processus et pratique de négociation</li>
                          </ul>
                          <ul style="margin-left: 2vw">
                            <h4>Plaidoyer</h4>
                            <li>Définition du plaidoyer</li>
                            <li>Plaidoyer pour soi</li>
                            <li>Plaidoyer pour une cause communautaire</li>
                            <li>Plaidoyer pour une circonscription</li>
                          </ul>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!--------------------------------------------------------------------->
                  <div style="width: 100%; display: flex; flex-direction: row;
                   justify-content: center; align-items: center">
                    <h2>3. GOUVERNANCE LOCALE ET ACTIONS CITOYENNES</h2>
                  </div>
                  <br>
                  <div style="width: 100%; display: flex; flex-direction: row;
                   justify-content: center; align-items: center">
                    <h3 style="text-decoration: underline">PERTINENCE ET CONTEXTUALISATION</h3>
                  </div>
                  <br>
                  <div style="width: 100%; display: flex; flex-direction: row;
                   justify-content: center; align-items: center; margin-bottom: 2vh">
                    <h2 style="text-decoration: underline; color: #f49835">Modules de formation</h2>
                  </div>
                  <!--------------------------------------------------------->
                  <v-simple-table style="background: #EEEEEE">
                    <template v-slot:default>
                      <thead>
                      <tr style="background: black;">
                        <th class="text-left" style="color: #FFFFFF">
                          <h2 style="font-weight: bold"> Module 1  &ensp;&ensp;&ensp;&ensp;: </h2>
                        </th>
                        <th class="text-left" style="color: #FFFFFF">
                          <h2>Collectivités Territoriales : gouvernance, statut, compétences,
                            organisation fonctionnement et acteurs
                          </h2>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td><h3 style="font-weight: bold">Problématique</h3> </td>
                        <td>
                          <br>
                          <p>
                            Pour impacter la gouvernance locale, il est essentiel d’en maitriser
                            les tenants et aboutissants. Dans ce vaste et complexe domaine de
                            gouvernance toute nouvelle, il est impérieux d’identifier le rôle
                            de chaque acteur (parties prenantes de la gouvernance) et saisir
                            d’un premier regard l’interdépendance des pouvoirs. Ce module permet
                            d’analyser les rôles, le statut, les compétences, les moyens d’action
                            et les rapports avec le pouvoir central. La superposition des échelons
                            administratifs et l’emboîtement des responsabilités des collectivités
                            territoriales sont des sujets de première importance. Il s’agit enfin
                            de comprendre l’organisation interne des CT et les conditions de
                            réussite de leurs actions.
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Public cible </h3>
                        </td>
                        <td>
                          <ul>
                            <li>Étudiants directs
                            </li>
                            <li>
                              Professionnels des collectivités territoriales.
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Volume horaire</h3>
                        </td>
                        <td> <span style="font-weight: bold">
                          50h dont 20h théorie et 30h de pratique
                        </span></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3>Objectif général </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Permettre aux acteurs civiles d’analyser le jeu des acteurs en relation
                            avec des stratégies de la gouvernance et de la décentralisation
                            des collectivités territoriales
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Objectifs pédagogiques </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Aux termes de la formation, les participants devront être
                            en mesure de :
                          </p>
                          <ul style="margin-left: 2vw">
                            <li>Décrire les léments structurels</li>
                            <li>Comprendre et maitriser les mécanismes de fonctionnement
                              des collectivités locales</li>
                            <li>Maitriser  les dimensions économiques, administratives
                              et financières de la gouvernance territoriale</li>
                          </ul>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Contenu</h3>
                        </td>
                        <td>
                          <p>
                            Eléments structurels de la gouvernance
                            <br>
                            Principes fondamentaux de décentralisation et de la déconcentration
                            Rôle, missions et attributions de l’État d’une part et des CT d’autre
                            part Principe de la subsidiarité
                            <br>
                            Processus de transfert de compétences et de ressources et de ses
                            impacts sur les CT Fonctionnement des CT
                            <br>
                            Missions et compétences des CT Organisation des CT
                            <br>
                            Analyse des principales causes de dysfonctionnements
                            <br>
                            Rôle et dynamique des principaux acteurs des CT et relations avec
                            les acteurs économiques et sociaux et la société civile
                          </p>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!--------------------------------------------------------------------->
                  <v-simple-table style="background: #EEEEEE">
                    <template v-slot:default>
                      <thead>
                      <tr style="background: black;">
                        <th class="text-left" style="color: #FFFFFF">
                          <h2 style="font-weight: bold"> Module 1  &ensp;&ensp;&ensp;&ensp;: </h2>
                        </th>
                        <th class="text-left" style="color: #FFFFFF">
                          <h2>Collectivités Territoriales : gouvernance, statut, compétences,
                            organisation fonctionnement et acteurs
                          </h2>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td><h3 style="font-weight: bold">Problématique</h3> </td>
                        <td>
                          <br>
                          <p>
                            Rôle de chacun des acteurs (collectivités locales, société civile,
                            acteurs socio-économiques) dans la gouvernance locale. Autrement
                            dit, comment optimiser la mise en œuvre  des outils et instruments
                            de la participation des citoyens dans l’action publique locale ?
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Public cible </h3>
                        </td>
                        <td>
                          <ul>
                            <li>Étudiants directs
                            </li>
                            <li>
                              Professionnels des collectivités territoriales.
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3 style="font-weight: bold">Volume horaire</h3>
                        </td>
                        <td> <span style="font-weight: bold">
                          35h dont 15h théorie et 20h de pratique
                        </span></td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr style="background: #FFFFFF"
                      >
                        <td>
                          <h3>Objectif général </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Permettre aux acteurs civils de s’approprier le processus de
                            participation des citoyens et des organisations de la société civile
                            à la gestion des affaires locales.
                          </p>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Objectifs pédagogiques </h3>
                        </td>
                        <td>
                          <br>
                          <p>
                            Aux termes de la formation, les participants
                            devront être en mesure de :
                          </p>
                          <ul style="margin-left: 2vw">
                            <li>répertorier et d’expliquer les droits et devoirs de tous
                              les acteurs des collectivités locales</li>
                            <li>analyser les principes et mécanismes de la démocratie participative
                              locale </li>
                            <li>De situer l’importance de l’information dans le processus de
                              la participation citoyenne et la
                              nécessité d’accéder à l’information

                            </li>
                          </ul>
                          <br>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      <tr
                      >
                        <td>
                          <h3>Contenu</h3>
                        </td>
                        <td>
                          <p>
                            Les acteurs de la démocratie participative locale institutionnelle
                            et non institutionnelle
                            <br>
                            Les outils, mécanismes et limites de la démocratie locale
                            <br>
                            Les notions et les enjeux de l’information des citoyens
                            <br>
                            La communication et le dialogue avec les citoyens et les OSC
                            <br>
                            L’accès aux documents administratifs,
                            <br>
                            La gouvernance ouverte (open governance).
                          </p>
                        </td>
                      </tr>
                      <!--------------------------------------------------------------------->
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-------------------------------------------------------------------------------->
<!--            <v-expansion-panel class="expansionPanel">-->
<!--              <v-expansion-panel-header v-bind:class="{addColor: this.panelHeader3}"-->
<!--                class="expansionPanelHeader"-->
<!--                style="display: flex;-->
<!--              flex-direction: row; justify-content: center;"-->
<!--              >-->
<!--                <span style="font-size: larger; font-weight: bold" class="titlePanelHeader">-->
<!--                  FORMATION CERTIFIANTE EN ADMINISTRATION </span>-->
<!--                <div style="display: flex; flex-direction: row; justify-content: flex-end">-->
<!--                  <v-chip-->
<!--                    color="#5b6d66"-->
<!--                    text-color="white"-->
<!--                    style="margin-right: 20px"-->
<!--                  >-->
<!--                    Choisir cette formation-->
<!--                  </v-chip>-->
<!--                </div>-->
<!--              </v-expansion-panel-header>-->
<!--              <v-expansion-panel-content>-->
<!--                <div style="margin-top: 10px">-->
<!--                  <p>-->
<!--                    &#45;&#45;-->
<!--                  </p>-->
<!--                  <p>-->
<!--                    &#45;&#45;-->
<!--                  </p>-->
<!--                </div>-->
<!--              </v-expansion-panel-content>-->
<!--            </v-expansion-panel>-->
            <!-------------------------------------------------------------------------------->
<!--            <v-expansion-panel class="expansionPanel">-->
<!--              <v-expansion-panel-header v-bind:class="{addColor: this.panelHeader5}"-->
<!--                class="expansionPanelHeader"-->
<!--                style="display: flex;-->
<!--              flex-direction: row; justify-content: center;"-->
<!--              >-->
<!--                <span style="font-size: larger; font-weight: bold" class="titlePanelHeader">-->
<!--                  FORMATION CERTIANTE EN GESTION INTERNATIONALE </span>-->
<!--                <div style="display: flex; flex-direction: row; justify-content: flex-end">-->
<!--                  <v-chip-->
<!--                    color="#5b6d66"-->
<!--                    text-color="white"-->
<!--                    style="margin-right: 20px"-->
<!--                  >-->
<!--                    Choisir cette formation-->
<!--                  </v-chip>-->
<!--                </div>-->
<!--              </v-expansion-panel-header>-->
<!--              <v-expansion-panel-content>-->
<!--                <div style="margin-top: 10px">-->
<!--                  <p>-->
<!--                    &#45;&#45;-->
<!--                  </p>-->
<!--                  <p>-->
<!--                    &#45;&#45;-->
<!--                  </p>-->
<!--                </div>-->
<!--              </v-expansion-panel-content>-->
<!--            </v-expansion-panel>-->
            <!-------------------------------------------------------------------------------->
<!--            <v-expansion-panel class="expansionPanel">-->
<!--              <v-expansion-panel-header v-bind:class="{addColor: this.panelHeader6}"-->
<!--                class="expansionPanelHeader"-->
<!--                style="display: flex;-->
<!--              flex-direction: row; justify-content: center;"-->
<!--              >-->
<!--                <span style="font-size: larger; font-weight: bold" class="titlePanelHeader">-->
<!--                  FORMATION CERTIFIANTE SEMI-SPÉCILISÉS </span>-->
<!--                <div style="display: flex; flex-direction: row; justify-content: flex-end">-->
<!--                  <v-chip-->
<!--                    color="#5b6d66"-->
<!--                    text-color="white"-->
<!--                    style="margin-right: 20px"-->
<!--                  >-->
<!--                    Choisir cette formation-->
<!--                  </v-chip>-->
<!--                </div>-->
<!--              </v-expansion-panel-header>-->
<!--              <v-expansion-panel-content>-->
<!--                <div style="margin-top: 10px">-->
<!--                  <p>-->
<!--                    &#45;&#45;-->
<!--                  </p>-->
<!--                  <p>-->
<!--                    &#45;&#45;-->
<!--                  </p>-->
<!--                </div>-->
<!--              </v-expansion-panel-content>-->
<!--            </v-expansion-panel>-->
            <!-------------------------------------------------------------------------------->
            <v-expansion-panel class="expansionPanel">
              <v-expansion-panel-header v-bind:class="{addColor: this.panelHeader3}"
                                        class="expansionPanelHeader"
                                        style="display: flex;
              flex-direction: row; justify-content: center;"
              >
                <span style="font-size: larger; font-weight: bold" class="titlePanelHeader">
                  FORMATION CERTIFIANTE EN ENTREPRENEURIAT </span>
                <div style="display: flex; flex-direction: row; justify-content: flex-end">
                  <v-chip
                    color="#5b6d66"
                    text-color="white"
                    style="margin-right: 20px"
                  >
                    Choisir cette formation
                  </v-chip>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="margin-top: 10px">
                  <ol>
                    <span style="margin-left: -2vw">
                    <span><span style="font-weight: bold">Bloc 1 :</span>
                      Modules « Prévenir »</span>
                    </span>
                    <li>La PME, l’entrepreneur et les réseaux</li>
                    <li>Choisir son entrepreneuriat </li>
                    <li>Le cheminement d’un projet entrepreneurial</li>
                    <br>
                    <span style="margin-left: -2vw">
                    <span><span style="font-weight: bold">Bloc 2 :</span>
                      Modules « Repérer »</span>
                    </span>
                    <li>Activité de Design Thinking</li>
                    <li>Créativité et innovation en pratique</li>
                    <br>
                    <span style="margin-left: -2vw">
                    <span><span style="font-weight: bold">Bloc 3 :</span>
                      Modules « Agir à l’interne »</span>
                    </span>
                    <li>Concrétiser son idée en projet d’affaire</li>
                    <li>Avenues entrepreneuriales</li>
                    <li>Comment valider une occasion d’affaires ?</li>
                    <li>Comment rejoindre le client (marketing entrepreneurial) ?</li>
                    <br>
                    <span style="margin-left: -2vw">
                    <span><span style="font-weight: bold">Bloc 4 :</span>
                      Modules « Agir à l’externe »</span>
                    </span>
                    <li>Le moment de vérité ou Présentation du projet (pratique du « pitch »!)</li>
                    <li>Plan d’affaires</li>
                    <li>Finances, Soutien et Mentorat</li>
                  </ol>
                  <div style="width: 100%; display: flex; flex-direction: row;
                   justify-content: center; align-items: center">
                    <h3 style="color: red"><span style="color: black">N.B</span> : Chaque
                      module a une durée
                      d’une semaine de formation</h3>
                  </div>
                  </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!------------------------------------------------------------------------>
            <!-------------------------------------------------------------------------------->
            <v-expansion-panel class="expansionPanel">
              <v-expansion-panel-header
                disabled
                v-bind:class="{addColor: this.panelHeader3}"
                                        class="expansionPanelHeader"
                                        style="display: flex;
              flex-direction: row; justify-content: center;"
              >
                <span style="font-size: larger; font-weight: bold" class="titlePanelHeader">
                  FORMATION CERTIFIANTE EN ADMINISTRATION </span>
                <div style="display: flex; flex-direction: row; justify-content: flex-end">
<!--                  <v-chip-->
<!--                    color="#5b6d66"-->
<!--                    text-color="white"-->
<!--                    style="margin-right: 20px"-->
<!--                  >-->
<!--                    Choisir cette formation-->
<!--                  </v-chip>-->
                </div>
              </v-expansion-panel-header>
<!--              <v-expansion-panel-content>-->
<!--              </v-expansion-panel-content>-->
            </v-expansion-panel>
            <!------------------------------------------------------------------------>
            <!-------------------------------------------------------------------------------->
            <v-expansion-panel class="expansionPanel">
              <v-expansion-panel-header v-bind:class="{addColor: this.panelHeader3}"
                                        class="expansionPanelHeader"
                                        style="display: flex;
              flex-direction: row; justify-content: center;"
              >
                <span style="font-size: larger; font-weight: bold" class="titlePanelHeader">
                  FORMATION CERTIFIANTE SEMI-SPÉCIALISÉE </span>
                <div style="display: flex; flex-direction: row; justify-content: flex-end">
<!--                  <v-chip-->
<!--                    color="#5b6d66"-->
<!--                    text-color="white"-->
<!--                    style="margin-right: 20px"-->
<!--                  >-->
<!--                    Choisir cette formation-->
<!--                  </v-chip>-->
                </div>
              </v-expansion-panel-header>
              <!--              <v-expansion-panel-content>-->
              <!--              </v-expansion-panel-content>-->
            </v-expansion-panel>
            <!------------------------------------------------------------------------>
            <!-------------------------------------------------------------------------------->
            <v-expansion-panel class="expansionPanel">
              <v-expansion-panel-header disabled
                v-bind:class="{addColor: this.panelHeader3}"
                                        class="expansionPanelHeader"
                                        style="display: flex;
              flex-direction: row; justify-content: center;"
              >
                <span style="font-size: larger; font-weight: bold" class="titlePanelHeader">
                  FORMATION CERTIFIANTE EN GESTION INTERNATIONALE </span>
                <div style="display: flex; flex-direction: row; justify-content: flex-end">
<!--                  <v-chip-->
<!--                    color="#5b6d66"-->
<!--                    text-color="white"-->
<!--                    style="margin-right: 20px"-->
<!--                  >-->
<!--                    Choisir cette formation-->
<!--                  </v-chip>-->
                </div>
              </v-expansion-panel-header>
              <!--              <v-expansion-panel-content>-->
              <!--              </v-expansion-panel-content>-->
            </v-expansion-panel>
            <!------------------------------------------------------------------------>
            <!------------------------------------------------------------------------>
          </v-expansion-panels>
        </div>
      </div>
      <!------------------------------------------------------------------------------->
      <div
        style="width: 100vw; display: flex; flex-direction: column; justify-content: center;
      align-items: center; margin-top: 0vh"
      >
        <v-card
          class="mx-auto"
          width="80%"
          color="#f49835"
          style="justify-content: center; align-items: center;
          flex-flow: column nowrap; display: flex;"
        >
          <v-card-text style="color: #000000; font-size: large; text-align: left; width: 100%">
            <ol>
              <li style="margin-bottom: 1vh"
              >Pour les Certifications Collégiales, des CÉGEP et Universitaires, le participant
                qui complète avec succès tous les modules indiqués selon son programme, recevra
                le diplôme de formation continue appropriée.</li>
              <li style="margin-bottom: 1vh"
              >Si vous suivez une formation sur les logiciels ou sur un thème quelconque,
                vous recevrez un certificat de participation
                de l’Académie Internationale Léono.</li>
              <li style="margin-bottom: 1vh"
              >Toutes nos formations peuvent être organisées sur demande pour un groupe
                de participants à des conditions avantageuses et aux dates prévues dans
                le calendrier annuel.</li>
              <li style="margin-bottom: 1vh"
              >Des formations de groupe (minimum de 25 personnes) peuvent
                être organisées à des dates de votre choix.
              </li>
              <li>Une formation est sujette à report ou éventuellement annulation si le
                nombre de participants inscrits et ayant payé est insuffisant (11 et moins).
                Si les dates changent, le responsable de la formation informera les participants
                inscrits au moins deux semaines avant la date initialement prévue.
                Si la formation était annulée par l’Académie, les participants déjà inscrits
                se verraient rembourser les frais d’inscription.</li>
            </ol>
            <br>
            <ul style="margin-left: 3vw">
              <li>En cliquant sur contact pour nous envoyer un message : <span
              class="contact" @click="goToContact">
                CONTACT
              </span></li>
              <li>En écrivant à l’adresse suivante : <a href=""
              style="color: #FFFFFF">secreteriat@ail-canada.org</a></li>
              <li>En appelant le numéro suivant : <span style="color: #FFFFFF"
              >+1819-602-8956 poste 221</span></li>
              <li>En envoyant un message à Télécopie : <span
              style="color:#FFFFFF">819-602-8957</span></li>
            </ul>
          </v-card-text>
        </v-card>
        <!------------------------------------------------------------------------------->
        <div
          style="width: 100vw; display: flex; flex-direction: column; justify-content: center;
      align-items: center; margin-top: 3vh"
        ></div>
          <div style="width: 80%; display: flex; margin-bottom: 10vh;
            flex-direction: row; justify-content: center; align-items: center">
            <v-btn class="v-btn--left" rounded x-large color="green" style="color: white">
              CONFIRMER</v-btn>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Formations',
  data: () => ({
    panelHeader1: false,
    panelHeader2: false,
    panelHeader3: false,
    panelHeader4: false,
    panelHeader5: false,
    panelHeader6: false,
    panelHeader7: false,
    isWhiteHeader1: false,
    isOrangeHeader1: true,
  }),
  methods: {
    formation1() {
      this.panelHeader1 = true;
    },
    changeTextColor1() {
      this.isWhiteHeader1 = true;
      this.isOrangeHeader1 = false;
    },
    putDefaultColor1() {
      this.isWhiteHeader1 = false;
      this.isOrangeHeader1 = true;
    },
    goToContact() {
      this.$router.push('/Contact');
    },
  },
};
</script>

<style scoped lang="css">
.expansionPanelHeader:hover{
  background: #97AEA5;
}

.expansionPanelHeader {
  background: #FFFFFF;
}

.expansionPanel {
  margin: 0px 0px 15px 0px
}

.titlePanelHeader {
  color: #f49835;
}

.addColor{
  background: #5b6d66;
}

.makeTextWhite{
  color: #FFFFFF !important;
}

.makeTextOrange{
  color: #f49835;
}
.PutContentWhite{
  background: #FFFFFF !important;
}
.putTheGoodStyle{
  font-family: Raleway !important;
}
.contact{
  cursor: pointer;
}
.contact:hover{
  color: #FFFFFF;
  background: #1a1e23;
}
</style>
