<template>
  <nav style="z-index: 99; position: fixed;" ref="navBarRef">
    <ul>
      <li><a @click="goToHomePage" id="tag1">ACCUEIL</a></li>
      <li style="white-space: nowrap;"><a id="tag2"
        style="white-space: nowrap;">À-PROPOS</a>
        <v-icon color="white">mdi-menu-down</v-icon>
        <ul>
          <li><a class="subMenu" href="#0" @click="goToMission">MISSION</a></li>
          <li><a class="subMenu" href="#0" @click="goToVision">VISION</a></li>
          <li><a class="subMenu" href="#0" @click="goToValeurs">VALEURS</a></li>
          <li><a class="subMenu" href="#0" @click="goToEquipe">ÉQUIPE</a></li>
        </ul>
      </li>
      <li style="white-space: nowrap;">
        <a id="tag3">SERVICES</a><v-icon color="white">mdi-menu-down</v-icon>
        <ul>
          <li><a class="subMenu" @click="goToFormationsView">CALENDRIER DE FORMATION</a></li>
        </ul>
      </li>
      <li style="white-space: nowrap;">
        <a id="tag4">SÉJOUR</a><v-icon color="white">mdi-menu-down</v-icon>
        <ul>
          <li><a class="subMenu" href="#0" @click="goToHebergement">HEBERGEMENT</a></li>
          <li><a class="subMenu" href="#0" @click="goToRestauration">RESTAURATION</a></li>
          <li><a class="subMenu" href="#0">DÉPLACEMENT</a></li>
          <li><a class="subMenu" style="white-space: nowrap !important;" href="#0"
          @click="goToLoisir">
            LOISIRS</a></li>
        </ul>
      </li>
      <li><a @click="goToPartenaire" id="tag5">PARTENAIRES</a></li>
      <li><a @click="goToContact" id="tag6">CONTACT</a></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NewHome',
  data() {
    return {
      scrollValue: 0,
    };
  },
  methods: {
    goToFormationsView() {
      this.$router.push('/Formations');
    },
    goToMission() {
      this.$router.push('/Missions');
    },
    goToVision() {
      this.$router.push('/Vision');
    },
    goToValeurs() {
      this.$router.push('/Valeurs');
    },
    goToEquipe() {
      this.$router.push('/Equipe');
    },
    goToPartenaire() {
      this.$router.push('/Partenaires');
    },
    goToHomePage() {
      this.$router.push('/');
    },
    goToContact() {
      this.$router.push('/Contact');
    },
    goToHebergement() {
      this.$router.push('/Hebergement');
    },
    goToRestauration() {
      this.$router.push('/Restauration');
    },
    goToLoisir() {
      this.$router.push('/Loisirs');
    },
    /**
     * ---> updates the scroll value in the circular progress bar of the navigation component
     * @return none
     */
    handleScroll() {
      const pos = document.documentElement.scrollTop;
      const calcHeight = document.documentElement.scrollHeight
        - document.documentElement.clientHeight;
      const scroll = (pos * 100) / calcHeight;
      this.scrollValue = Math.round(scroll);
      if (this.scrollValue > 2) {
        this.$refs.navBarRef.style.background = '#f49835';
        document.getElementById('tag1').style.color = '#FFFFFF';
        document.getElementById('tag2').style.color = '#FFFFFF';
        document.getElementById('tag3').style.color = '#FFFFFF';
        document.getElementById('tag4').style.color = '#FFFFFF';
        document.getElementById('tag5').style.color = '#FFFFFF';
        document.getElementById('tag6').style.color = '#FFFFFF';
      } else {
        this.$refs.navBarRef.style.background = '';
        document.getElementById('tag1').style.color = '';
        document.getElementById('tag2').style.color = '';
        document.getElementById('tag3').style.color = '';
        document.getElementById('tag4').style.color = '';
        document.getElementById('tag5').style.color = '';
        document.getElementById('tag6').style.color = '';
      }
    },
  },
  mounted() {
    // event listener that triggers the handleScroll() method
    window.addEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
body {
  background: #F9F8FD;
  font-family: 'Lexend Exa', sans-serif;
}

nav {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  animation: slide-in 1s ease-out;
  border: 2px solid #EBECF1;
  border-radius: 1px;
}

ul {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  margin: 0;
  padding: 0 30px;
  list-style-type: none;
  li:not(:last-child) {
    margin-right: 40px;
    //border: 2px solid red;
    //border-radius: 1px;
  }
  li {
    border: 2px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: background 0.2s;
    a {
      color: #FFFFFF;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 0.2s;
    }
    .subMenu{
      color: #000000 !important;
    }
    .subMenu:hover{
      color: #f49835 !important;
    }
    ul {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      display: block;
      margin: 12px -12px;
      padding: 0;
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      border-right: 2px solid #FFFFFF;
      border-bottom: 2px solid #FFFFFF;
      border-radius: 1px;
      transition: opacity 0.2s, visibility 0.2s;
      li {
        margin: -2px 0 0 -2px;
        width: calc(100% - 20px);
        line-height: 1.7;
        a {
          color: #2375D8;
        }
      }
    }
    &:hover {
      a {
        color: #f49835;
      }
      ul {
        visibility: visible;
        opacity: 1;
        li {
          a {
            color: #F9F8FD;
          }
        }
      }
    }
  }
}

@keyframes slide-in {
  0% {
    top: -50px;
  }
  40% {
    top: 20px;
  }
  70% {
    top: 10px;
  }
  100% {
    top: 15px;
  }
}
</style>
