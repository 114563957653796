<template>
 <div style="background: black; height: 100vh; width: 100vw"></div>
</template>

<script>
export default {
  name: 'Partenaires',
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
