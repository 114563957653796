<template>
<div style="">
<!--  <v-img src="../assets/images/mission.png" max-height="25vh">-->
<!--    <div-->
<!--      style="width: 100vw; display: flex; flex-direction: row; justify-content: center;-->
<!--    align-items: center; height: 100%;"-->
<!--    >-->
<!--    <span style="color: #f49835; font-size: 10vh;-->
<!--        font-weight: bold">MISSION</span>-->
<!--    </div>-->
<!--  </v-img>-->
<!--  <div style="display: flex; flex-flow: row nowrap; height: 100%">-->
<!--    <div style="height: content-box; width: 40vw; justify-content: end;-->
<!--        align-items: center; display: flex; flex-flow: row nowrap;">-->
<!--&lt;!&ndash;          <div style="background: #e6d98c; height: 50%; width: 50%">&ndash;&gt;-->
<!--&lt;!&ndash;            <h3>MISSION</h3>&ndash;&gt;-->
<!--&lt;!&ndash;            <hr style="width:100%;text-align:left;margin-left:0;">&ndash;&gt;-->
<!--&lt;!&ndash;            <h3>VISION</h3>&ndash;&gt;-->
<!--&lt;!&ndash;            <h3>VALEURS</h3>&ndash;&gt;-->
<!--&lt;!&ndash;            <h3>ÉQUIPE</h3>&ndash;&gt;-->
<!--&lt;!&ndash;            <h3>PARTENAIRES</h3>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--      <v-card-->
<!--        width="40%"-->
<!--        color="#e6d98c"-->
<!--        style="justify-content: center; align-items: center;-->
<!--        flex-flow: column nowrap; display: flex"-->
<!--      >-->
<!--        <div style="width: content-box;-->
<!--          display: flex; flex-flow: column nowrap;-->
<!--          justify-content: center; align-items: center">-->
<!--          <v-card-title-->
<!--            style="font-family: Raleway; font-size: x-large"-->
<!--          >MISSION</v-card-title>-->
<!--          <v-divider style="background: #008363; width: 100%;"-->
<!--          ></v-divider>-->
<!--          <v-card-title-->
<!--            style="font-family: Raleway; font-size: x-large"-->
<!--          >VISION</v-card-title>-->
<!--          <v-divider style="background: #008363; width: 100%;"-->
<!--          ></v-divider>-->
<!--          <v-card-title-->
<!--            style="font-family: Raleway; font-size: x-large"-->
<!--          >VALEURS</v-card-title>-->
<!--          <v-divider style="background: #008363; width: 100%;"-->
<!--          ></v-divider>-->
<!--          <v-card-title-->
<!--            style="font-family: Raleway; font-size: x-large"-->
<!--          >ÉQUIPE</v-card-title>-->
<!--          <v-divider style="background: #008363; width: 100%;"-->
<!--          ></v-divider>-->
<!--          <v-card-title-->
<!--            style="font-family: Raleway; font-size: x-large"-->
<!--          >PARTENAIRES</v-card-title>-->
<!--        </div>-->
<!--      </v-card>-->
<!--    </div>-->
<!--    <div style="height: 80vh; width: 60vw; justify-content: center;-->
<!--        align-items: center; display: flex; flex-flow: row nowrap">-->
<!--        <v-card-->
<!--          class="mx-auto"-->
<!--          width="80%"-->
<!--          outlined-->
<!--          color="#EEEEEE"-->
<!--        >-->
<!--          <v-card-text style="color: black; font-size: medium;-->
<!--            font-family: Raleway;font-size: large">-->
<!--            &ensp;&ensp;&ensp;&ensp;&ensp;-->
<!--            L’ Académie Internationale des Libertés (AIL) est un centre d’excellence-->
<!--            orienté métiers. Les formations dispensées en son sein, ont pour-->
<!--            objectif d’édifier les auditeurs. Pour maintenir élevée la qualité-->
<!--            de la formation y dispensées et ceci de façon permanente, l’AIL dans-->
<!--            sa stratégie, s’affilie à des entités ayant reçues de hautes distinctions-->
<!--            et accréditations dans leur domaines d’expertises.-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--    </div>-->
<!--  </div>-->
  <v-img src="../assets/images/mission.png" height="200vh">
    <div style="height: 100%; width: 100%; display: flex;
            position: relative; position: relative; height: 100vh; width: 100vw;
            flex-flow: column nowrap; justify-content: center; align-items: center">
        <div style="display: flex; flex-flow: row nowrap; justify-content: center;
          align-items: center; height: 50vh; width: 100vw">
          <div class="container">
            <div class="box">
              <div class="title">
                <span class="block"></span>
                <h1>MISSION<span></span></h1>
              </div>
            </div>
          </div>
          <v-card
            class="mx-auto"
            width="50%"
            height="auto"
            outlined
            color="#EEEEEE"
            style="opacity: 0.8"
          >
            <v-card-text style="color: #000000; opacity: 1 !important; font-size: medium;
              font-family: Raleway;font-size: large; z-index: 3;">
              &ensp;&ensp;&ensp;&ensp;&ensp;
              L’ Académie Internationale des Libertés (AIL) est un centre d’excellence
              orienté métiers. Les formations dispensées en son sein, ont pour
              objectif d’édifier les auditeurs. Pour maintenir élevée la qualité
              de la formation y dispensées et ceci de façon permanente, l’AIL dans
              sa stratégie, s’affilie à des entités ayant reçues de hautes distinctions
              et accréditations dans leur domaines d’expertises.
            </v-card-text>
          </v-card>
        </div>
      <!--------------------------------------------------------------------------->
      <div style="display: flex; flex-flow: row nowrap;
       justify-content: start;
          align-items: center; height: 50vh; width: 100vw">
       <v-card
         class="mx-auto"
         width="50%"
         height="auto"
         outlined
         color="#EEEEEE"
         style="opacity: 0.8"
       >
         <v-card-text style="color: #000000; opacity: 1 !important; font-size: medium;
              font-family: Raleway;font-size: large; z-index: 3;">
           &ensp;&ensp;&ensp;&ensp;&ensp;
           L’ AIL promeut la liberté en développant les connaissances pratiques
           éprouvées scientifiquement chez ses auditeurs. L’AIL favorise la justice et
           l’équité sociale et l’harmonie entre les diversités culturelles. Nul ne
           pouvant être heureux tout seul, l’Académie Internationale des Libertés a
           une vision, celle de contribuer à mener les couches défavorisées à un
           mieux-être social et moral à travers toutes ses activités et au sein des
           différents partenaires associés.
         </v-card-text>
       </v-card>
        <div class="container" style="">
          <div class="box">
            <div class="title">
              <span class="block"></span>
              <h1>VISION<span></span></h1>
            </div>
          </div>
        </div>
     </div>
     </div>
    <!--------------------------------------------------------------------------->
    <div style="display: flex; flex-flow: column nowrap;
    justify-content: center;
          align-items: center; height: 100vh">
      <div class="container" style="margin-bottom: 8vh">
        <div class="box">
          <div class="title">
            <span class="block"></span>
            <h1>MISSION<span></span></h1>
          </div>
        </div>
      </div>
      <v-card
        class="mx-auto"
        width="95%"
        max-height="80vh"
        height="auto"
        outlined
        color="#EEEEEE"
        style="opacity: 0.8"
      >
        <v-card-text style="color: black; font-size: medium;
            font-family: Raleway;font-size: large">
          Consciente de la vérité, autrefois énoncée par Rabelais mais toujours
          d’actualité, que « Science sans conscience n’est que ruine de l’âme »,
          L’AIL œuvre à éveiller la conscience en ses bénéficiaires, c’est-à-dire
          à les éveiller à la vertu, tout en leur apportant l’instruction, à travers
          ses différents programmes. L’éthique est donc une valeur importante pour
          notre institution. Dans le même ordre d’idées, en regroupant différentes
          cultures et valeurs en son sein, l’institution est bien au fait de
          la relativité éthique et encourage par la même occasion, l’acceptation
          ainsi que l’adaptation aux valeurs d’harmonies et de divertés.
          <br><br>
          L’Académie Internationale des Libertés s’appuie  sur un socle
          de valeurs qui guident ses actions, ainsi nous prônons:
          <br><br>
          <span style="font-weight: bold">Le Respect :</span>Toujours avoir à
          l’esprit que chacun est lui, et mérite que
          l’on respecte sa dignité, qu’importe son rang social, et quelle que soit
          son appartenance culturelle, religieuse ou sa couleur de peau.
          <br><br>
          <span style="font-weight: bold">L’Abnégation :</span>Le service par amour
          pour son prochain et pour l’intérêt général, et non pour l’intérêt personnel
          et égoïste.
          <br><br>
          <span style="font-weight: bold">La Foi : </span>Se souvenir toujours qu’on a toutes
          les possibilités en soi, de réaliser ses désirs. Car, ce que l’esprit humain
          peut concevoir, l’esprit humain peut le réaliser.
          <br><br>
          <span style="font-weight: bold">L’Intégrité :</span>Être fidèle à soi-même,
          c’est-à-dire à ses valeurs morales, sans jamais se laisser corrompre par des
          contingences extérieures.
          <br><br>
          <span style="font-weight: bold">L’Humilité : </span>L’homme n’est rien sans Dieu
          et sans les autres. Garder cela en tête, et s’en souvenir chaque fois qu’on veut
          poser un acte.
          <br><br>
          <span style="font-weight: bold">L’Exemple :</span>Toute personne intervenant dans l’un
          ou l’autre des secteurs de L’Académie Internationale des Libertés doit être un exemple
          vivant des valeurs qu’elle prône. L’éducation par l’exemple est notre maître-mot.
        </v-card-text>
      </v-card>
    </div>
    <!--------------------------------------------------------------------------->
  </v-img>
</div>
</template>

<script>
export default {
  name: 'QuiSommesNous',
};
</script>

<style scoped>

@import url("https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap");

.container {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .box {
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.container .box .title {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
}
.container .box .title .block {
  width: 0%;
  height: inherit;
  background: #f49835;
  position: absolute;
  animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  display: flex;
}
.container .box .title h1 {
  font-family: "Poppins";
  color: #fff;
  font-size: 60px;
  -webkit-animation: mainFadeIn 2s forwards;
  -o-animation: mainFadeIn 2s forwards;
  animation: mainFadeIn 2s forwards;
  animation-delay: 1.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
}
.container .box .title h1 span {
  width: 0px;
  height: 0px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #f49835;
  -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  bottom: 13px;
  right: -12px;
}
.container .box .role {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 15px;
}
.container .box .role .block {
  width: 0%;
  height: inherit;
  background: #5b6d66;
  position: absolute;
  animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  display: flex;
}
.container .box .role p {
  animation: secFadeIn 2s forwards;
  animation-delay: 3.2s;
  opacity: 0;
  font-weight: 400;
  font-family: "Lato";
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #e9d856;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #e9d856;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 13px;
  }
}
@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
footer {
  width: 350px;
  height: 80px;
  background: #ffb510;
  position: absolute;
  right: 0;
  bottom: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: top 0.8s forwards;
  animation-delay: 4s;
}
footer span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #232323;
  font-family: "Poppins";
}
footer span i {
  margin-right: 25px;
  font-size: 22px;
  color: #232323;
  animation: icon 2s forwards;
  animation-delay: 4s;
  opacity: 0;
}

@keyframes top {
  0% {
    opacity: 0;
    bottom: -80px;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}
@keyframes icon {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.3) rotate(-2deg);
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}
</style>
